import styled from 'styled-components';
import { Button, Footer } from '@ondeck/silkworm';

export const StyledButton = styled(Button)`
  color: var(--fontColorsSecondary);
  font-family: var(--fontSemiBold);
  font-size: 11px;
  &:hover {
    color: var(--fontColorsSecondary);
  }
`;

export const MainContent = styled.main`
  flex: 1;
  max-width: var(--maxContentBox);
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledFooter = styled(Footer)`
  flex-shrink: 0;
  a {
    color: white;
  }
`;
