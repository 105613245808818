import { FormattedMessage } from 'react-intl';
import { Button, Link } from '@ondeck/silkworm';
import React, { useCallback, useEffect, useState } from 'react';
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkError
} from 'react-plaid-link';
import { getPlaidClientInfo, trackPlaid } from 'service/service';
import { useAuth } from 'contexts/AuthContext';
import { Alert, Card, Container, Row, Stack } from 'react-bootstrap';
import Logo from 'assets/logo-color.svg';
import PlaidLogo from 'assets/Plaid_Logo_Black.svg';
import GreenCheckmark from 'assets/green-checkmark.svg';

export interface PlaidRequest {
  callback: string;
  error: string;
  eventName: string;
  metadata: string;
  publicToken: string;
  variation: string;
}

interface PlaidProps {
  onPlaidDismissed: () => void;
  onPlaidConnect?: () => void;
  skip_plaid_btn?: boolean;
}

const PlaidBenefits = ({ messageId }: { messageId: string }) => (
  <Stack direction="horizontal" gap={2} className="align-items-start">
    <img
      src={GreenCheckmark}
      alt="Green Checkmark"
      style={{ width: 24, height: 24, paddingTop: 6 }}
    />
    <span>
      <FormattedMessage
        id={messageId}
        values={{ b: (text: React.ReactNode) => <b>{text}</b> }}
      />
    </span>
  </Stack>
);

const Plaid = ({
  onPlaidDismissed,
  onPlaidConnect,
  skip_plaid_btn = true
}: PlaidProps) => {
  const { addCredentials } = useAuth();
  const [errorAlert, setErrorAlert] = useState(false);
  const [linkToken, setLinkToken] = useState('');
  const [plaidSuccess, setPlaidSuccess] = useState(false);
  const [skipPlaidBtn, setSkipPlaidBtn] = useState(skip_plaid_btn);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      setErrorAlert(false);
      try {
        const track = await trackPlaid(addCredentials, {
          callback: 'onSuccess',
          metadata: JSON.stringify(metadata),
          publicToken: public_token,
          eventName: 'OPEN',
          error: '',
          variation: skip_plaid_btn ? 'skip_enabled' : 'skip_disabled'
        });
        if (track?.ok) {
          setPlaidSuccess(true);
          onPlaidDismissed();
        } else {
          setErrorAlert(true);
        }
      } catch (e) {
        setErrorAlert(true);
      }
    },
    []
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    async (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
      try {
        setLinkToken('');
        if (error) {
          setErrorAlert(true);
          if (
            error.error_code === 'INSUFFICIENT_CREDENTIALS' ||
            error.error_code === 'INVALID_CREDENTIALS'
          ) {
            if (sessionStorage.getItem('attempted') || false) {
              setSkipPlaidBtn(true);
            } else {
              sessionStorage.setItem('attempted', 'true');
            }
          } else {
            setSkipPlaidBtn(true);
          }
        }
        await trackPlaid(addCredentials, {
          callback: 'onExit',
          metadata: JSON.stringify(metadata),
          publicToken: '',
          eventName: 'EXIT',
          error: error ? JSON.stringify(error) : '',
          variation: skip_plaid_btn ? 'skip_enabled' : 'skip_disabled'
        });
      } catch (e) {}
    },
    []
  );

  const config: PlaidLinkOptions = {
    onSuccess,
    onExit,
    token: linkToken
  };

  const { open, ready } = usePlaidLink(config);

  const launchPlaid = async () => {
    setErrorAlert(false);
    const clientInfo = await getPlaidClientInfo(addCredentials);
    clientInfo?.link_token
      ? setLinkToken(clientInfo?.link_token)
      : setErrorAlert(true);
    onPlaidConnect && onPlaidConnect();
  };

  useEffect(() => {
    document.body.style.overflow = 'auto';
    !plaidSuccess && !errorAlert && ready && open();
  }, [ready, launchPlaid, errorAlert, plaidSuccess]);

  return (
    <>
      <Stack
        gap={4}
        data-testid="plaid-loaded"
        className="text-center container"
      >
        <div>
          {errorAlert && (
            <Alert
              variant="danger"
              className="p-3 text-start"
              style={{ background: '#fdf3f4', color: '#c91528' }}
            >
              <FormattedMessage id="plaid.error" />
            </Alert>
          )}
          <h2
            className="mt-4 fw-semibold text-secondary ms-1 text-center"
            style={{
              fontSize: '36px',
              marginBottom: '24px',
              alignSelf: 'stretch',
              lineHeight: 1.25
            }}
          >
            <FormattedMessage id="plaid.title" />
          </h2>
          <Card className="py-4 px-3 px-sm-5 mh-100 customCard shadow">
            <Stack gap={4}>
              <Stack
                gap={4}
                direction={'horizontal'}
                className={'justify-content-center align-items-center'}
              >
                <img
                  aria-label="Logo:Svg"
                  src={Logo}
                  alt="onDeck"
                  style={{ width: 140.5, height: 30.142 }}
                />
                <img
                  aria-label="PlaidLogo:Svg"
                  src={PlaidLogo}
                  alt="Plaid"
                  style={{ width: 126, height: 48 }}
                />
              </Stack>

              <Stack className={'text-start'}>
                <FormattedMessage id="plaid.intro" />
              </Stack>
              <Stack gap={3} className={'text-start align-items-start'}>
                {[
                  'plaid.benefits.one',
                  'plaid.benefits.two',
                  'plaid.benefits.three'
                ].map(id => (
                  <PlaidBenefits key={id} messageId={id} />
                ))}
              </Stack>
              <Stack className={'text-start'}>
                <FormattedMessage id="plaid.conclusion" />
              </Stack>
            </Stack>
          </Card>
        </div>

        <Container>
          <Row>
            <Button
              className="rounded-pill col col-md-6 mx-auto"
              onClick={() => launchPlaid()}
            >
              <FormattedMessage id="plaid.button" />
            </Button>
          </Row>
          {skipPlaidBtn && (
            <Row>
              <Link
                className="fw-bold link-primary col pt-4"
                onClick={onPlaidDismissed}
              >
                <FormattedMessage id="plaid.notNow" />
              </Link>
            </Row>
          )}
        </Container>
        <p
          className="fs-5 px-lg-5 mx-lg-4 text-start"
          style={{ lineHeight: 1.5 }}
        >
          <FormattedMessage
            id="plaid.footer.message"
            values={{
              link: (text: React.ReactNode) => (
                <Link
                  href="https://my.plaid.com"
                  className="link-primary fw-bold"
                >
                  {text}
                </Link>
              ),
              privacy: (text: React.ReactNode) => (
                <Link
                  href="https://plaid.com/legal/"
                  className="link-primary fw-bold"
                >
                  {text}
                </Link>
              ),
              safety: (text: React.ReactNode) => (
                <Link
                  href="https://plaid.com/safety/"
                  className="link-primary fw-bold"
                >
                  {text}
                </Link>
              )
            }}
            tagName="p"
          />
        </p>
      </Stack>
    </>
  );
};

export default Plaid;
