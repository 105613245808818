import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { LoadingSpinner, useErrorHandler } from '@ondeck/silkworm';
import { getParties, postNextState } from 'service';
import { Checkout } from 'states/checkoutState';
import { useAuth } from '../../contexts/AuthContext';

interface OfferPresentmentProps {
  checkoutData: Checkout;
  onCheckoutDataChange(checkoutData: object): void;
}

const OfferPresentment = ({
  checkoutData,
  onCheckoutDataChange
}: OfferPresentmentProps) => {
  const { addCredentials } = useAuth();
  const { handleError } = useErrorHandler();

  useEffect(() => {
    (async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const offerId = queryParams.get('offerId');
        const accepted = queryParams.get('accepted') === 'true';

        if (offerId && accepted) {
          // user came back from Offer Presentment UX
          const checkoutDataRes = await postNextState(
            addCredentials,
            checkoutData.checkoutUuid,
            offerId,
            checkoutData.uxComponentRef
          );
          onCheckoutDataChange(checkoutDataRes);
          window.history.replaceState(undefined, '', '/');
        } else {
          const partiesData = await getParties(addCredentials);
          partiesData.optimizely?.monorepoOfferPresentment
            ? window.location.replace(
                `${window.env.REACT_APP_MONOREPO_URL}/checkout`
              )
            : window.location.replace(
                `${
                  window.env.REACT_APP_OFFER_PRESENTMENT_URL
                }/redirect/auth?applicationId=${checkoutData.applicationUuid}${
                  checkoutData.offerUuid
                    ? `&offerId=${checkoutData.offerUuid}`
                    : ''
                }`
              );
        }
      } catch (e) {
        handleError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingSpinner>
      <FormattedMessage id="common.loading.message" />
      <div data-testid="offerPresentment-loaded" />
    </LoadingSpinner>
  );
};

export default OfferPresentment;
