import { InputSliderCombo } from 'components/common/InputSliderCombo';
import React, { useEffect, useState } from 'react';
import { Button, Card, Stack, Alert } from 'react-bootstrap';
import { mQ, RequiredFirstDrawContainer } from 'pages/styles/common';
import { Offer, offerInitialState } from '../../states/offerState';
import { MoneyFormat } from 'components/TextFormat';
import { calculatedEstimatedPayment } from 'pages/helpers/helpers';
import { Checkout } from '../../states/checkoutState';
import { getDetailedOffer, postNextState } from '../../service';
import { useAuth } from '../../contexts/AuthContext';
import { LoadingSpinner, useErrorHandler } from '@ondeck/silkworm';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { LoanDetails, loanDetailsInitialState } from 'states/loanDetailsState';

const RequiredFirstDrawWrapper = styled.div`
  ${mQ.md`
    margin: 0 auto;
  `}
`;
const StyledAlert = styled(Alert)`
  background-color: #fff;
  border: none;
  font-size: 0.875rem;
  line-height: 1.5em;
  margin-bottom: 1em;
  margin-top: -0.5rem;
  padding: 0;
  ${mQ.md`
    margin-top: .5em;
  `}
`;
interface inputSliderProps {
  initialValue?: number;
}

interface RequiredFirstDrawProps {
  checkoutData: Checkout;
  isLoadingInit?: boolean;
  onCheckoutDataChange(checkoutData: object): void;
  initialValue: number;
}

export const RequiredFirstDraw = ({
  checkoutData,
  isLoadingInit = true,
  onCheckoutDataChange,
  initialValue
}: RequiredFirstDrawProps) => {
  const [isLoading, setIsLoading] = useState(isLoadingInit);
  const [inputSliderValue, setInputSliderValue] = useState<inputSliderProps>({
    initialValue
  });
  const { isAuthenticated, addCredentials } = useAuth();
  const [offerData, setOfferData] = useState<Offer>(offerInitialState);
  const { handleError } = useErrorHandler();
  const { handleSubmit } = useForm();
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  // @ts-ignore: Coverage ignore next
  /* istanbul ignore next */
  const showAlert = message => {
    setAlertMessage(message);
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), 3000);
  };
  const handleInputSliderChange = newValue => {
    setInputSliderValue(newValue);
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  const [loanDetailsData, setLoanDetailsData] = useState<LoanDetails>(
    loanDetailsInitialState
  );
  const sliderInitialValue = offerData.lineAmount / 2;
  const requestedDollarData = {
    inputSliderValue,
    apr: offerData.trueAPR,
    paymentFrequency: offerData.paymentFrequency.toUpperCase(),
    term: offerData.term,
    numberOfPeriods: loanDetailsData?.numberOfPeriods
  };

  const estimatedPayment = calculatedEstimatedPayment(requestedDollarData);

  useEffect(() => {
    isAuthenticated &&
      (async () => {
        try {
          const offerDataResponse = await getDetailedOffer(
            addCredentials,
            checkoutData.applicationUuid,
            checkoutData.offerUuid
          );
          setOfferData(offerDataResponse);
          setLoanDetailsData(offerDataResponse);
        } catch (e) {
          handleError(e);
        }
        setIsLoading(false);
      })();
  }, [isAuthenticated]);

  const submitForm = async () => {
    setIsLoading(true);
    try {
      const checkoutDataRes = await postNextState(
        addCredentials,
        checkoutData.checkoutUuid,
        checkoutData.offerUuid,
        checkoutData.uxComponentRef,
        undefined,
        undefined,
        undefined,
        inputSliderValue as number,
        estimatedPayment
      );
      onCheckoutDataChange(checkoutDataRes);
    } catch (e) {
      handleError(e);
    }
    setIsLoading(false);
  };

  return isLoading ? (
    <LoadingSpinner>
      <FormattedMessage id="common.loading.message" />
    </LoadingSpinner>
  ) : (
    <RequiredFirstDrawWrapper>
      <form
        id="requiredFirstDraw"
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={checkKeyDown}
      >
        <Stack gap={4} className="text-center">
          <h1
            className={`fw-semibold text-secondary ms-1`}
            style={{ fontSize: '36px' }}
          >
            Select your first draw amount.
          </h1>
          <Card className="p-4 text-start shadow">
            <RequiredFirstDrawContainer>
              <Stack gap={4}>
                <div>
                  <div className="fw-bold">Choose or enter an amount.</div>
                  <div className="fs-5">
                    The minimum required draw is{' '}
                    <MoneyFormat
                      value={offerData?.requiredInitialDraw}
                      variant="text"
                    />
                    .
                  </div>
                </div>

                <div>
                  <InputSliderCombo
                    min={offerData?.requiredInitialDraw}
                    max={offerData?.lineAmount}
                    sm={7}
                    initialValue={sliderInitialValue}
                    onChange={handleInputSliderChange}
                    onAlert={showAlert}
                  />

                  {alertVisible && (
                    <StyledAlert
                      variant="danger"
                      className="requiredFirstDrawAlert"
                    >
                      {alertMessage}
                    </StyledAlert>
                  )}
                </div>
                <div
                  style={{
                    borderTop: '2px solid #eee',
                    marginTop: '-10px',
                    paddingTop: '30px'
                  }}
                >
                  <div className="fw-bold">Repayment Schedule</div>
                  <div className="mt-3">
                    <span className="fw-bold">
                      {offerData?.numberOfPayments}{' '}
                      {offerData?.paymentFrequency.toUpperCase() === 'WEEKLY'
                        ? 'weekly'
                        : 'monthly'}
                    </span>{' '}
                    estimated payments of{' '}
                    <span className="fw-bold">
                      <MoneyFormat value={estimatedPayment} variant="text" />
                    </span>{' '}
                    based on the selected draw.
                  </div>
                </div>
              </Stack>
            </RequiredFirstDrawContainer>
          </Card>
          <div>
            After checkout, your funds will be deposited into your bank account
            within 2 - 3 business days.
          </div>
          <div>
            <Button
              className="rounded-pill px-5"
              type="submit"
              form="requiredFirstDraw"
            >
              Continue
            </Button>
          </div>
        </Stack>
      </form>
    </RequiredFirstDrawWrapper>
  );
};

export default RequiredFirstDraw;
