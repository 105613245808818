import React from 'react';
import { Button, GenericMessage, Link, PhoneLink } from '@ondeck/silkworm';
import { useIntl, FormattedMessage } from 'react-intl';
import Page500 from 'assets/page500.svg';

const TechnicalDifficultiesPage = () => {
  const intl = useIntl();
  return (
    <div className="mt-5" aria-label="GenericMessage:Wrapper">
      <GenericMessage
        image={Page500}
        title={intl.formatMessage({ id: 'technicalDifficulties.title' })}
      >
        <p className="mt-3">
          <FormattedMessage
            id="technicalDifficulties.content"
            values={{
              phoneLink: (
                <PhoneLink
                  phoneNumber={intl.formatMessage({
                    id: 'common.contactNumber'
                  })}
                />
              ),
              emailLink: (
                <Link
                  href={`mailto:${intl.formatMessage({
                    id: 'technicalDifficulties.emailLink'
                  })}`}
                  role="link"
                >
                  <FormattedMessage id="technicalDifficulties.emailCopy" />
                </Link>
              )
            }}
          />
        </p>
        <Link href={window.env.REACT_APP_BAZAAR_URL} target="_self">
          <Button>
            <FormattedMessage id="common.button.returnHome" />
          </Button>
        </Link>
      </GenericMessage>
    </div>
  );
};

export default TechnicalDifficultiesPage;
