import React from 'react';
import { FormattedDate } from 'react-intl';
import {
  Margin,
  WrapperAgreements,
  Header,
  StyledTitle,
  StyledParagraph,
  StyledHr
} from 'pages/styles/common';
// generated from /frontend/scripts
import lastModifiedDate from 'pages/generated-dates/credit-agreement-date';

const CreditAgreementPage = () => (
  <Margin>
    <WrapperAgreements data-testid="Wrapper:Agreements">
      <Header data-testid="DateHeader:StyledDiv">
        Updated and effective{' '}
        <FormattedDate
          value={lastModifiedDate}
          year="numeric"
          month="long"
          day="2-digit"
        />
      </Header>
      <StyledTitle>Credit and Background Check Authorization</StyledTitle>
      <StyledParagraph>
        This authorization applies to OnDeck Capital, Inc., ODK Capital, LLC,
        and any of its affiliates, subsidiaries, agents doing business under the
        On Deck name, and assignees (collectively “OnDeck”, “Us”, “Our”, or
        We”). You understand and agree that OnDeck and our agents and assignees
        are authorized to contact third parties to conduct background checks and
        other investigative reports, including but not limited to your business
        credit card sales data as described below, and make credit report
        inquiries (including requesting personal credit bureau reports about you
        and any other owner(s) of the business who will be providing a personal
        guarantee, and business credit bureaus about your company, in either
        case, from credit reporting agencies and other sources) or for any other
        lawful purpose. OnDeck has such authority for the foregoing in
        connection with any extension of credit to the company on whose behalf
        you are applying, conducting loan file updates, ongoing loan reviews,
        renewal of financing, or referral of your business to third party
        lenders. Upon your written request, we will advise you if we obtained a
        credit report. You understand and agree that OnDeck is making no
        commitment or guarantee that any product, evaluation, or guidance
        provided by OnDeck will result in an approval of credit from OnDeck or
        any OnDeck Banking Partner.
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>Credit Card Data Collection</StyledTitle>
      <StyledParagraph>
        You understand and agree that We and Our agents and assignees are
        authorized to contact third party credit card processors and networks
        used by your company in order to obtain historical and ongoing
        information/metrics related to:
      </StyledParagraph>
      <ol>
        <li>
          Your company, including, but not limited to, company name, address,
          phone number, and industry; and
        </li>
        <br />
        <li>
          Your company’s credit card sales, including, but not limited to,
          transaction spend, number of transactions, and repeat customers.
        </li>
      </ol>
      <br />
      <StyledParagraph>
        Such information and metrics will be used by OnDeck for purposes of
        providing the OnDeck Service, including, but are not limited to, sales
        agent call routing, underwriting, customer management, and enhancement
        of OnDeck’s credit model.
      </StyledParagraph>
    </WrapperAgreements>
  </Margin>
);

export default CreditAgreementPage;
