import React, { useEffect, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';
/* eslint-disable react/prop-types */
const renderThumb = ({ props }) => (
  <div
    {...props}
    style={{
      ...props.style,
      height: '29px',
      width: '29px',
      borderRadius: '50%',
      border: '2px solid #E7E7F4',
      backgroundColor: '#006BEB'
    }}
    data-testid="thumb"
  />
);

const RenderTrack = ({ props, children, sliderValues, min, max }) => {
  return (
    <div
      aria-hidden="true"
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      style={{
        ...props.style,
        height: '36px',
        display: 'flex',
        width: '100%'
      }}
    >
      <div
        ref={props.ref}
        style={{
          height: '11px',
          width: '100%',
          borderRadius: '11px',
          background: getTrackBackground({
            values: sliderValues,
            colors: ['#000082', '#dddddd'],
            min: min,
            max: max
          }),
          alignSelf: 'center'
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const SimpleSlider = ({
  min = 0,
  max,
  value = 100,
  onChange = e => {},
  ...props
}) => {
  const [sliderValues, setSliderValues] = useState<number[]>([value]);

  useEffect(() => {
    setSliderValues([value]);
  }, [value]);
  return (
    <Range
      min={min}
      max={max}
      values={sliderValues}
      onChange={v => {
        setSliderValues(v);
        onChange(v[0]);
      }}
      renderTrack={({ props, children }) => (
        <RenderTrack
          props={props}
          sliderValues={sliderValues}
          min={min}
          max={max}
        >
          {children}
        </RenderTrack>
      )}
      renderThumb={renderThumb}
    />
  );
};
