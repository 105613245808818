import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import toolTip from 'assets/tool_tip.svg';

export const ToolTip = ({
  className,
  text,
  placement = 'bottom'
}: {
  className?: string;
  text: any;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip className={'height: 16px'}>
          <div className={className}>{text}</div>
        </Tooltip>
      }
    >
      <img src={toolTip} alt="more info" width="16px" className="ms-1" />
    </OverlayTrigger>
  );
};

export default ToolTip;
