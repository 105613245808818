export interface BankAccount {
  bankName: string;
  accountNumber: string;
  routingNumber: string;
}

export const bankInfoInitialState: BankAccount = {
  bankName: 'OnDeck',
  accountNumber: '123456789',
  routingNumber: '987654321'
};
