import React, { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { FormattedMessage } from 'react-intl';

interface MoneyInputProps {
  min?: number;
  max?: number;
  value?: number;
  onChange?: Function;
  onAlert?: Function;
  currency?: string;
  round?: boolean;
}

export const MoneyInput = ({
  min = 0,
  max = 10000000,
  value = 0,
  currency = '$',
  onChange = () => {},
  onAlert = () => {},
  round = true
}: MoneyInputProps) => {
  const [checkedValue, setCheckedValue] = useState(value);
  const [localValue, setLocalValue] = useState(value);

  const checkBounds = value => {
    if (value > max) {
      setCheckedValue(max);
      onAlert(
        <FormattedMessage id="offer.reviewWithRequiredFirstDraw.overMaxAlert" />
      );
      setLocalValue(max);
      return;
    }
    if (value < min) {
      setCheckedValue(min);
      onAlert(
        <FormattedMessage id="offer.reviewWithRequiredFirstDraw.underMinAlert" />
      );
      setLocalValue(min);
      return;
    }

    value = round ? Math.round(value) : value;
    setCheckedValue(value);
    value !== localValue && setLocalValue(value);
  };

  useEffect(() => {
    checkBounds(value);
  }, [value]);

  useEffect(() => {
    value !== checkedValue && onChange(checkedValue);
  }, [checkedValue]);

  return (
    <InputGroup className="mb-3" size="lg">
      <InputGroup.Text className="">{currency}</InputGroup.Text>
      <NumberFormat
        className="form-control"
        thousandSeparator={true}
        value={localValue}
        data-testid="MoneyInput"
        onValueChange={v => {
          v.floatValue && setLocalValue(v.floatValue);
        }}
        onBlur={v => {
          checkBounds(localValue);
        }}
      />
    </InputGroup>
  );
};
