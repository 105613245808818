import React, { useContext, useEffect, useState } from 'react';

interface IAuthContext {
  isAuthenticated: boolean;
  isInitializing: boolean;
  user?: User;
  addCredentials: (options: any) => void;
  logout: Function;
}

const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  isInitializing: true,
  addCredentials: () => {},
  logout: () => {}
});

export const useAuth = () => useContext(AuthContext);

export const AuthResolver = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isInitializing, setInitializing] = useState(true);
  const [user, setUser] = useState();
  const redirectHttpCodes = [303, 401, 403];

  useEffect(() => {
    if (window.location.pathname === '/login-dev') {
      setInitializing(false);
    } else {
      fetch(`${window.env.REACT_APP_SPICEROAD_URL}/v1/users/profile`, {
        credentials: 'include'
      }).then(response => {
        if (redirectHttpCodes.includes(response.status)) {
          window.location.href = window.env.REACT_APP_LOGIN_PAGE_URL;
        } else if (response.status === 200) {
          setAuthenticated(true);
          response.json().then(rsBody => setUser(rsBody));
        }
        setInitializing(false);
      });
    }
  }, []);

  return (
    <AuthProvider
      isAuthenticated={isAuthenticated}
      isInitializing={isInitializing}
      user={user}
    >
      {children}
    </AuthProvider>
  );
};

const AuthProvider = ({
  children,
  isAuthenticated,
  isInitializing,
  user
}: {
  children: React.ReactNode;
  isAuthenticated: boolean;
  isInitializing: boolean;
  user?: User;
}) => {
  const context = {
    isAuthenticated,
    isInitializing,
    user,
    addCredentials: (options: any) => {
      options.credentials = 'include';
    },
    logout: () => {
      fetch(`${window.env.REACT_APP_SPICEROAD_URL}/v1/users/logout`, {
        method: 'POST',
        credentials: 'include'
      }).then(() => {
        window.location.href = window.env.REACT_APP_LOGIN_PAGE_URL;
      });
    }
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
