import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  FooterContainer,
  Link,
  Button,
  BackArrow,
  PhoneLink,
  ConfirmInformation
} from '@ondeck/silkworm';
import { useIntl, FormattedMessage } from 'react-intl';
import { useTracking } from 'react-tracking';

const StyledConfirmInformation = styled(ConfirmInformation)`
  div {
    min-height: unset;
  }

  div[class*='CardContent'] {
    margin-top: -25px;
  }
`;

const MainTextWrapper = styled.div`
  font-size: var(--fontSizesButton);
  line-height: 24px;
  margin: 0 auto;
  max-width: 488px;
  span {
    font-size: var(--fontSizesBody);
  }
`;

const SubTextWrapper = styled.div`
  color: #6f738c;
  font-size: var(--fontSizesSubtext);
  margin-top: 32px;
`;

const UnableToProceed = () => {
  const intl = useIntl();
  const { trackEvent } = useTracking();
  const eventName = 'Unable To Proceed';
  const transitionEventName = `${eventName} Transition`;
  const handleBackClick = () =>
    window.location.assign(window.env.REACT_APP_BAZAAR_URL);
  useEffect(() => {
    trackEvent({ event: `${eventName} Loaded` });
    trackEvent({ isTimed: true, event: transitionEventName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BackArrow onClick={handleBackClick} absolutePositioned={true} />
      <StyledConfirmInformation
        title={intl.formatMessage({ id: 'unableToProceed.title' })}
        content={
          <>
            <MainTextWrapper>
              <FormattedMessage
                id="unableToProceed.mainText"
                values={{
                  phoneLink: (
                    <PhoneLink
                      phoneNumber={intl.formatMessage({
                        id: 'common.contactNumber'
                      })}
                    />
                  )
                }}
              />
            </MainTextWrapper>
            <SubTextWrapper>
              <FormattedMessage id="unableToProceed.subtext" />
            </SubTextWrapper>
          </>
        }
        footer={
          <FooterContainer>
            <Link href={window.env.REACT_APP_BAZAAR_URL} target="_self">
              <Button
                onClick={() => {
                  trackEvent({
                    event: transitionEventName,
                    button: 'Return Home'
                  });
                }}
              >
                <FormattedMessage id="common.button.returnHome" />
              </Button>
            </Link>
          </FooterContainer>
        }
      />
    </>
  );
};

export default UnableToProceed;
