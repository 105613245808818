import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTracking } from 'react-tracking';
import {
  BackArrow,
  Button,
  ConfirmInformation,
  FooterContainer
} from '@ondeck/silkworm';
import { titleCase } from 'pages/helpers/helpers';

const Content = styled.div`
  h1,
  div {
    font-size: var(--fontSizesBody);
  }
  h1 {
    font-family: var(--fontBold);
  }
`;

const FooterContainerColumn = styled(FooterContainer)`
  align-items: center;
  flex-direction: column;
  row-gap: 12px;
`;

const FooterText = styled.div`
  color: var(--fontColorsSubtext);
  font-size: var(--fontSizesSubtext);
`;

interface ConfirmBankProps {
  onBack: Function;
  onNext: Function;
  bankAccount: {
    bankName: string;
    accountNumber: string;
    routingNumber: string;
  };
}

const ConfirmBank = ({ onBack, onNext, bankAccount }: ConfirmBankProps) => {
  const { formatMessage } = useIntl();
  const { trackEvent } = useTracking();
  const eventName = 'Confirm Bank Page';
  const transitionEventName = `${eventName} Transition`;

  useEffect(() => {
    trackEvent({ event: `${eventName} Loaded` });
    trackEvent({ isTimed: true, event: transitionEventName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BackArrow
        onClick={onBack}
        absolutePositioned={true}
        data-testid="BackArrow:StyledButton"
      />
      <ConfirmInformation
        title={formatMessage({ id: 'confirmation.bank.title' })}
        subhead={formatMessage({ id: 'confirmation.bank.subtitle' })}
        content={
          bankAccount && (
            <Content>
              <h1>{titleCase(bankAccount.bankName)}</h1>
              <div>
                {bankAccount.accountNumber.replace(/\d(?=\d{4})/g, 'X')}
              </div>
            </Content>
          )
        }
        footer={
          <FooterContainerColumn>
            <Button
              onClick={() => {
                onNext(bankAccount);
                trackEvent({ event: eventName, button: 'Continue' });
              }}
            >
              <FormattedMessage id="confirmation.button.continue" />
            </Button>
            <FooterText>
              <FormattedMessage id="confirmation.bank.subtext" />
            </FooterText>
          </FooterContainerColumn>
        }
      />
    </>
  );
};

export default ConfirmBank;
