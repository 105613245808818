import { PlaidRequest } from 'components/Plaid/Plaid';
import type { IdentityVerificationAnswerData } from 'states/identityVerificationState';

export const timeoutFetch = (endpoint: string, options: any, timeout = 30000) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Timeout Error'));
    }, timeout);
    fetch(endpoint, options)
      .then(async response => {
        if (response.ok) {
          resolve(response);
        } else if (response.status === 401) {
          window.location.href = window.env.REACT_APP_LOGIN_PAGE_URL;
          resolve(response);
        } else {
          const message = await response.text();
          const status = response.status;
          reject({ status, message });
        }
      })
      .catch(error => reject(new Error(error.message)));
  });

// Checkout-orchestrator

export const startCheckout = async (
  addCredentials: Function,
  applicationUuid: string
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify({
      applicationUuid,
      channel: 'DIRECT',
      product: 'TermLoan',
      region: 'US'
    })
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/orchestration/start`,
    options
  );
  const { uxComponentRef, data } = await response.json();
  return { uxComponentRef, ...data };
};

export const getCheckoutState = async (
  addCredentials: Function,
  applicationId?: string
) => {
  const applicationSearch = applicationId
    ? '?applicationUuid=' + applicationId
    : '';
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/orchestration/state${applicationSearch}`,
    options
  );
  const { uxComponentRef, data } = await response.json();
  return { uxComponentRef, ...data };
};

export const postNextState = async (
  addCredentials: Function,
  checkoutUuid: string,
  offerUuid: string,
  currentUxComponentRef: string,
  routingNumber?: string,
  accountNumber?: string,
  bankName?: string,
  selectedDrawAmount?: number,
  estimatedPaymentAmount?: number
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify({
      offerUuid,
      currentUxComponentRef,
      routingNumber,
      accountNumber,
      bankName,
      selectedDrawAmount,
      estimatedPaymentAmount
    })
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/orchestration/next/${checkoutUuid}`,
    options
  );
  const { uxComponentRef, data } = await response.json();
  return { uxComponentRef, ...data };
};

export const postPreviousState = async (
  addCredentials: Function,
  checkoutUuid: string,
  currentUxComponentRef: string
) => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      currentUxComponentRef
    })
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/orchestration/previous/${checkoutUuid}`,
    options
  );
  const { uxComponentRef, data } = await response.json();
  return { uxComponentRef, ...data };
};

export const completeApplication = async (
  addCredentials: Function,
  checkoutUuid: string,
  currentUxComponentRef: string
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify({
      currentUxComponentRef
    })
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/orchestration/complete/${checkoutUuid}`,
    options
  );
  const { uxComponentRef, data } = await response.json();
  return { uxComponentRef, ...data };
};

export const getContract = async (
  addCredentials: Function,
  checkoutUuid: string
) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/contracts/${checkoutUuid}`,
    options
  );
  return response;
};

export const getDisclosure = async (
  addCredentials: Function,
  checkoutUuid: string
) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/disclosures/${checkoutUuid}`,
    options
  );
  return response;
};

export const getBankInfo = async (
  addCredentials: Function,
  checkoutUuid: string
) => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/bank-info/${checkoutUuid}`,
    options
  );
  return response.json();
};

// Identity Verification

export const getIdentityVerificationQuestions = async (
  addCredentials: Function,
  checkoutUuid: string
) => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/identity-verification/${checkoutUuid}/iq`,
    options
  );
  return response.json();
};

export const submitIdentityVerificationAnswers = async (
  addCredentials: Function,
  checkoutUuid: string,
  IdentityVerificationAnswersData: IdentityVerificationAnswerData
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(IdentityVerificationAnswersData)
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/identity-verification/${checkoutUuid}/iq`,
    options
  );
  return response;
};

export const getIdentityChallengeQuestions = async (
  addCredentials: Function,
  checkoutUuid: string,
  idNumber: string
) => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/identity-verification/${checkoutUuid}/challenge?idNumber=${idNumber}`,
    options
  );
  return response.json();
};

export const submitIdentityChallengeAnswers = async (
  addCredentials: Function,
  checkoutUuid: string,
  IdentityVerificationAnswersData: IdentityVerificationAnswerData
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(IdentityVerificationAnswersData)
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/identity-verification/${checkoutUuid}/challenge`,
    options
  );
  return response;
};

// Offer-orchestrator

export const getDetailedOffer = async (
  addCredentials: Function,
  applicationId: string,
  offerId: string
) => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_OFFER_ORCHESTRATOR_URL}/detailedOffers?applicationId=${applicationId}&offerId=${offerId}`,
    options
  );
  const { applicationOffer, loanDetails } = await response.json();
  if (applicationOffer?.selectedOffer) {
    return {
      ...applicationOffer.offers.filter(
        offer => offer.offerId === applicationOffer.selectedOffer
      )[0],
      ...applicationOffer.offers.filter(
        offer => offer.offerId === applicationOffer.selectedOffer
      )[0].pricedDetails,
      disbursementAmt: applicationOffer.offers.filter(
        offer => offer.offerId === applicationOffer.selectedOffer
      )[0].presentableDisbursementAmt,
      remainingPrincipal: loanDetails?.remainingPrincipal
    };
  } else
    return {
      ...applicationOffer.offers[0],
      ...applicationOffer.offers[0].pricedDetails,
      disbursementAmt: applicationOffer.offers[0].presentableDisbursementAmt,
      remainingPrincipal: loanDetails?.remainingPrincipal
    };
};

// Butterfly
export const getApplication = async (addCredentials: Function) => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_BUTTERFLY_URL}/v2/orchestration/current_application`,
    options
  );
  return response.json();
};

// Spice-road

export const getParties = async (addCredentials: Function) => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_SPICEROAD_URL}/v1/parties`,
    options
  );
  return response.json();
};

export const getPlaidClientInfo = async (addCredentials: Function) => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_SPICEROAD_URL}/v1/plaid/client-info`,
    options
  );
  return response.json();
};

export const trackPlaid = async (
  addCredentials: Function,
  plaidEvent: PlaidRequest
) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(plaidEvent),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_SPICEROAD_URL}/v1/plaid/track/merchant`,
    options
  );
  const text = await response.text();
  if (!text) {
    return { ok: response.ok };
  } else {
    return JSON.parse(text);
  }
};

export const getPlaidMerchantStatus = async (
  addCredentials: Function,
  opportunityId: string
) => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_SPICEROAD_URL}/v1/plaid/merchant/status?opportunityId=${opportunityId}`,
    options
  );
  return response.json();
};

export const validateRouting = async (
  addCredentials: Function,
  routingNumber: string
) => {
  const data = { bankRoutingNumber: routingNumber, countryCode: 'USA' };
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/bank-info/get-bank-details`,
    options
  );
  return response.json();
};

export const validateAccountNumber = async (
  addCredentials: Function,
  data: { accountNumber: string; applicationId: string }
) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL}/v1/bank-info/validate-bank-account`,
    options
  );
  return response.json();
};

export const isInstitutionSupported = async (
  addCredentials: Function,
  routingNumber: string
) => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  };
  await addCredentials(options);
  const response: any = await timeoutFetch(
    `${window.env.REACT_APP_SPICEROAD_URL}/v1/plaid/institutionSupport?routingNumber=${routingNumber}`,
    options
  );
  return response.json();
};

export const optimizelyTrack = async (
  addCredentials: Function,
  userId: string,
  trackRequest: { eventKey: string; attributes: any }
) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(trackRequest),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  await addCredentials(options);
  await timeoutFetch(
    `${window.env.REACT_APP_SPICEROAD_URL}/v1/optimizely/track/${userId}`,
    options
  );
};
