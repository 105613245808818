import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  codToCents,
  isStateRegSpecific,
  titleCase
} from '../../pages/helpers/helpers';
import { Offer } from '../../states/offerState';
import { Tooltip } from '@ondeck/silkworm';
import { TooltipWrapper } from '../TermsAndAgreements/TermsAndAgreements.styles';
import { DecimalFormat, MoneyFormat } from '../TextFormat';
import { Application } from '../../states/applicationState';
import { LoanDetails } from '../../states/loanDetailsState';

interface TermLoanReviewProps {
  offerData: Offer;
  application: Application;
  loanDetailsData: LoanDetails | null;
}

const TermLoanReview = ({
  offerData,
  application,
  loanDetailsData
}: TermLoanReviewProps) => {
  const paymentFrequencyTooltip = () => {
    const tooltipMessages: any = {
      daily: 'common.tooltip.paymentFrequency.daily',
      weekly: 'common.tooltip.paymentFrequency.weekly',
      monthly: 'common.tooltip.paymentFrequency.monthly'
    };
    return tooltipMessages[offerData.paymentFrequency.toLowerCase()];
  };

  const stateRegSpecificText = isStateRegSpecific(application)
    ? '.stateRegSpecific'
    : '';

  const prePaymentDiscountPct = offerData?.prePaymentDiscountPct ? 100 : 25;
  const amountType =
    loanDetailsData?.remainingPrincipal &&
    loanDetailsData?.remainingPrincipal > 0
      ? 'netAmount'
      : 'disbursementAmount';

  return (
    <div className="text-center" data-testid="TLOffer">
      <Stack gap={2} className="d-flex flex-md-row m-5">
        <Stack direction="vertical" className="mx-auto col-md-3">
          <MoneyFormat
            className="display-1"
            value={offerData.lineAmount}
            cents={false}
          />
          <p className="m-2 p-1">
            <FormattedMessage
              id={`termsAndAgreements.${
                amountType + stateRegSpecificText
              }.totalLoan`}
              values={{
                disbursementAmt: (
                  <DecimalFormat value={offerData.disbursementAmt} prefix="$" />
                )
              }}
            />
          </p>
        </Stack>
        <div className="d-none d-md-block vr border border-dark" />
        <hr className="d-block d-md-none border border-dark" />
        <Stack direction="vertical" className="mx-auto col-md-3">
          <MoneyFormat className="display-1" value={offerData?.payment} />
          <p className="m-2">
            <FormattedMessage
              id="termsAndAgreements.monthlyPayment1"
              values={{
                paymentFrequency: titleCase(offerData?.paymentFrequency),
                term: offerData?.term
              }}
            />
            <br />
            <FormattedMessage
              id="termsAndAgreements.monthlyPayment2"
              values={{
                monthlyPayment: (
                  <DecimalFormat
                    prefix="$"
                    value={offerData?.averageMonthlyPayment}
                  />
                )
              }}
            />
            <TooltipWrapper>
              <Tooltip>
                <FormattedMessage id={paymentFrequencyTooltip()} />
              </Tooltip>
            </TooltipWrapper>
          </p>
        </Stack>
      </Stack>
      <Card className="bg-light p-3 m-3 m-md-5">
        <Stack gap={4} className="fs-4 flex-md-row text-start lh-1">
          <Stack gap={3} className="col-md-3">
            <div className="fs-3 fw-bold">Details</div>
            <Stack direction="horizontal">
              <FormattedMessage
                id={`termLoanReview.loanAmt${stateRegSpecificText}.label`}
              />
              <MoneyFormat value={offerData?.lineAmount} className="ms-auto" />
            </Stack>
            <Stack direction="horizontal">
              {'Origination fee'}
              <TooltipWrapper>
                <Tooltip>
                  <FormattedMessage id="common.toolTip.originationFee" />
                </Tooltip>
              </TooltipWrapper>
              <MoneyFormat
                value={offerData?.loanOriginationFee}
                className="ms-auto"
              />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessage id={`termLoanReview.${amountType}.label`} />
              <TooltipWrapper>
                <Tooltip>
                  <FormattedMessage
                    id={`common.toolTip.${amountType}.disbursement`}
                  />
                </Tooltip>
              </TooltipWrapper>
              <MoneyFormat
                value={offerData?.disbursementAmt}
                className="ms-auto"
              />
            </Stack>
            <Stack direction="horizontal">
              {'Cents on the dollar (CoD)'}
              <DecimalFormat
                value={codToCents(offerData?.trueCentsOnDollar)}
                suffix="¢"
                toFixed
                round={true}
                decimalScale={1}
                className="ms-auto"
              />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessage
                id={`termLoanReview.aprText${stateRegSpecificText}.label`}
              />
              <TooltipWrapper>
                <Tooltip>
                  <FormattedMessage
                    id={`common.toolTip.apr${stateRegSpecificText}`}
                  />
                </Tooltip>
              </TooltipWrapper>
              <DecimalFormat
                value={offerData?.trueAPR}
                className="ms-auto"
                suffix={'%'}
              />
            </Stack>
          </Stack>
          <div className="d-none d-md-block vr border border-dark" />
          <hr className="d-block d-md-none border border-dark" />
          <Stack gap={3} className="col-md-3">
            <div className="fs-3 fw-bold">Cost & Fees</div>
            <Stack direction="horizontal">
              <FormattedMessage
                id={`termLoanReview.totalCostOfCapital${stateRegSpecificText}.label`}
              />
              <TooltipWrapper>
                <Tooltip>
                  <FormattedMessage id="common.toolTip.totalCostOfCapital" />
                </Tooltip>
              </TooltipWrapper>
              <MoneyFormat
                value={offerData?.totalLoanCost}
                className="ms-auto"
              />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessage
                id={`termLoanReview.totalRepaymentAmount${stateRegSpecificText}.label`}
              />
              <TooltipWrapper>
                <Tooltip>
                  <FormattedMessage
                    id={`common.toolTip.${application.type?.toLowerCase()}.${prePaymentDiscountPct}.totalRepaymentAmount`}
                  />
                </Tooltip>
              </TooltipWrapper>
              <MoneyFormat
                value={offerData?.totalAmountPaidBack}
                className="ms-auto"
              />
            </Stack>
            <Stack direction="horizontal">
              {'Returned payment fee'}
              <MoneyFormat
                value={offerData?.returnedPaymentFee}
                className="ms-auto"
              />
            </Stack>
            <Stack direction="horizontal">
              {'Late fee'}
              <MoneyFormat value={offerData?.lateFee} className="ms-auto" />
            </Stack>
          </Stack>
        </Stack>
        <Stack className="mt-4 fs-4 text-start">
          <div className="fs-3 fw-bold mb-2">
            {prePaymentDiscountPct}% Prepayment Reduction
          </div>
          <FormattedMessage
            id={`common.toolTip.${application.type?.toLowerCase()}.${prePaymentDiscountPct}.totalRepaymentAmount`}
          />
        </Stack>
      </Card>
    </div>
  );
};

export default TermLoanReview;
