import mixpanel from 'mixpanel-browser';
import { datadogRum } from '@datadog/browser-rum';

interface User {
  userId: string;
  name: string;
  email: string;
}

export const Mixpanel = {
  init: (apiToken: string) => mixpanel.init(apiToken),
  track: (data: { event: string; [key: string]: string }) => {
    const { event, isTimed, ...properties } = data;
    if (isTimed) {
      mixpanel.time_event(event);
    } else {
      mixpanel.track(event, properties);
    }
  },
  identify: id => {
    const oldID = mixpanel.get_distinct_id();
    mixpanel.identify(id);
    mixpanel.alias(oldID, id);
  },
  reset: () => mixpanel.reset()
};

export const DatadogSetUser = (user: User) => {
  datadogRum.setUser({
    id: user?.userId,
    name: user?.name,
    email: user?.email
  });
};
