export interface Checkout {
  applicationUuid: string;
  checkoutUuid: string;
  offerUuid: string;
  uxComponentRef: string;
  selectedDrawAmount?: any;
  estimatedPaymentAmount?: any;
  disclosureUuid?: string;
  routingNumber?: string;
}

export const checkoutInitialState: Checkout = {
  checkoutUuid: '',
  applicationUuid: '',
  offerUuid: '',
  uxComponentRef: '',
  selectedDrawAmount: '',
  routingNumber: ''
};
