import React, { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
  DefaultTheme,
  ErrorHandlerProvider,
  Authorize
} from '@ondeck/silkworm';
import { useTracking } from 'react-tracking';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Mixpanel } from 'analytics';
import AppContent from 'components/AppContent';
import CheckoutPage from 'pages/CheckoutPage';
import NotFoundPage from 'pages/NotFoundPage';
import TermsPage from 'pages/TermsPage';
import CreditAgreementPage from './pages/CreditAgreementPage';
import EsignAgreementPage from 'pages/EsignAgreementPage';
import FullStory from 'components/FullStory';
import { locale, messages } from './locales';
import LoginDev from './pages/LoginDev/LoginDev';
import { AuthResolver } from './contexts/AuthContext';
import { UserDataProvider } from './contexts/UserContext';

const App = () => {
  const [hideSignOut, setHideSignOut] = useState(false);
  const { Track } = useTracking(
    { application: 'Checkout UX' },
    {
      dispatch: (data: any) => {
        Mixpanel.track(data);
      }
    }
  );
  /* istanbul ignore next */
  return (
    <Track>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <ErrorHandlerProvider>
          <AuthResolver>
            <UserDataProvider>
              {window.env.REACT_APP_FULLSTORY_ENABLED && <FullStory />}
              <DefaultTheme />
              <BrowserRouter>
                <Switch>
                  {window.env.REACT_APP_ENV === 'development' && (
                    <Route exact path="/login-dev" component={LoginDev} />
                  )}
                  <Route exact path="/terms" component={TermsPage} />
                  <Route
                    exact
                    path="/creditagreement"
                    component={CreditAgreementPage}
                  />
                  <Route
                    exact
                    path="/esignagreement"
                    component={EsignAgreementPage}
                  />
                  <AppContent hideSignOut={hideSignOut}>
                    <Switch>
                      <Route exact path="/" component={CheckoutPage} />
                      <Route>
                        <NotFoundPage setHideSignOut={setHideSignOut} />
                      </Route>
                    </Switch>
                  </AppContent>
                </Switch>
              </BrowserRouter>
            </UserDataProvider>
          </AuthResolver>
        </ErrorHandlerProvider>
      </IntlProvider>
    </Track>
  );
};

export default App;
