import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Mixpanel } from 'analytics';
import {
  LoadingSpinner,
  useErrorHandler,
  SteppedProgressBar
} from '@ondeck/silkworm';
import { Checkout, checkoutInitialState } from 'states/checkoutState';
import { getCheckoutState, startCheckout, getApplication } from 'service';
import OfferPresentment from 'components/OfferPresentment';
import InformationConfirmation from 'components/InformationConfirmation';
import IdentityVerification from 'components/IdentityVerification';
import RequiredFirstDraw from 'components/RequiredFirstDraw';
import IdentityChallengeQuestion from 'components/IdentityChallengeQuestion';
import TermsAndAgreements from 'components/TermsAndAgreements';
import CheckoutSuccess from 'components/CheckoutSuccess';
import UnableToProceed from 'components/UnableToProceed';
import OffRamp from 'components/OffRamp';
import UnableToConfirmIdentity from 'components/UnableToConfirmIdentity';
import { Application, applicationInitialState } from 'states/applicationState';
import {
  messages_loc_renewal,
  messages_loc_new,
  messages_tl_renewal,
  messages_tl_new
} from './helpers/helpers';
import { offerInitialState, Offer } from 'states/offerState';
import { getDetailedOffer } from 'service';
import { useAuth } from '../contexts/AuthContext';
import { useUserData } from '../contexts/UserContext';
import PlaidStep from 'components/PlaidStep';

export enum CheckoutComps {
  OfferPresentment = 'OfferPresentment', // all 4
  InformationConfirmation = 'InformationConfirmation',
  Plaid = 'Plaid',
  IdentityVerification = 'IdentityVerification',
  RequiredFirstDraw = 'RequiredFirstDraw',
  IdentityChallengeQuestion = 'IdentityChallengeQuestion',
  TermsAndAgreements = 'TermsAndAgreements',
  CheckoutSuccess = 'CheckoutSuccess',
  UnableToProceed = 'UnableToProceed',
  OffRamp = 'OffRamp',
  UnableToConfirmIdentity = 'UnableToConfirmIdentity'
}

const CheckoutMappingStep = {
  OfferPresentment: { TL: 1, LOC: 1 },
  InformationConfirmation: { TL: 2.4, LOC: 2.3 },
  Plaid: { TL: 2.4, LOC: 2.3 },
  IdentityVerification: { TL: 3.6, LOC: 3.4 },
  IdentityChallengeQuestion: { TL: 3.6, LOC: 3.4 },
  RequiredFirstDraw: { TL: 3.6, LOC: 4.7 },
  UnableToProceed: { TL: 3.6, LOC: 4.7 },
  OffRamp: { TL: 3.6, LOC: 4.7 },
  UnableToConfirmIdentity: { TL: 3.6, LOC: 4.7 },
  TermsAndAgreements: { TL: 4.9, LOC: 5.9 },
  CheckoutSuccess: { TL: 6, LOC: 7 }
};

const CheckoutPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [checkoutData, setCheckoutData] = useState<Checkout>(
    checkoutInitialState
  );
  const [application, setApplication] = useState<Application>(
    applicationInitialState
  );
  const [offerData, setOfferData] = useState<Offer>(offerInitialState);
  const intl = useIntl();
  const intlMessages = (messages: any[]) =>
    messages.map(message => intl.formatMessage(message));

  // hooks
  const { isInitializing, isAuthenticated, addCredentials } = useAuth();
  const { userId } = useUserData();
  const { handleError } = useErrorHandler();

  function breadcrumbsMessages() {
    if (offerData.product && application.type) {
      return intlMessages(
        offerData.product === 'LineOfCredit'
          ? application.type === 'NEW'
            ? messages_loc_new
            : messages_loc_renewal
          : application.type === 'NEW'
          ? messages_tl_new
          : messages_tl_renewal
      );
    } else {
      return [];
    }
  }

  function checkoutMappingStepType() {
    if (offerData.product) {
      return offerData.product === 'LineOfCredit' ? 'LOC' : 'TL';
    } else {
      return 'TL';
    }
  }

  useEffect(() => {
    if (!isInitializing) {
      if (isAuthenticated && userId) {
        (async () => {
          try {
            Mixpanel.identify(userId);

            // get the most recent Application by User
            const applicationResponse = (await getApplication(
              addCredentials
            )) as Application;

            // get most recent Checkout by User and Application ID (if present)
            getCheckoutState(addCredentials, applicationResponse.id)
              .then(res => {
                setCheckoutData(res);
              })
              .catch(e => {
                // If first visit and checkout doesn't exist
                if (e.status === 404) {
                  (async () => {
                    applicationResponse.id
                      ? setCheckoutData(
                          await startCheckout(
                            addCredentials,
                            applicationResponse.id
                          )
                        )
                      : handleError(new Error('Missing [applicationId]'));
                  })();
                } else {
                  handleError(e);
                }
              });
            setApplication(applicationResponse);
          } catch (e) {
            handleError(e);
          }
        })();
      } else {
        Mixpanel.reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitializing, userId]);

  useEffect(() => {
    if (!isInitializing) {
      if (isAuthenticated && userId) {
        if (
          checkoutData.applicationUuid != null &&
          checkoutData.offerUuid != null
        ) {
          (async () => {
            try {
              getDetailedOffer(
                addCredentials,
                checkoutData.applicationUuid,
                checkoutData.offerUuid
              )
                .then(res => {
                  setOfferData(res);
                })
                .catch(e => {
                  handleError(e);
                });
            } catch (e) {
              handleError(e);
            }
          })();
        }
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutData.applicationUuid, checkoutData.offerUuid]);

  const renderComponent = uxComponentRef => {
    switch (uxComponentRef) {
      case CheckoutComps.OfferPresentment:
        return (
          <OfferPresentment
            checkoutData={checkoutData}
            onCheckoutDataChange={setCheckoutData}
          />
        );
      case CheckoutComps.InformationConfirmation:
        return (
          <InformationConfirmation
            application={application}
            checkoutData={checkoutData}
            onCheckoutDataChange={setCheckoutData}
          />
        );
      case CheckoutComps.Plaid:
        return (
          <PlaidStep
            application={application}
            checkoutData={checkoutData}
            onCheckoutDataChange={setCheckoutData}
          />
        );
      case CheckoutComps.IdentityVerification:
        return (
          <IdentityVerification
            checkoutData={checkoutData}
            onCheckoutDataChange={setCheckoutData}
          />
        );
      case CheckoutComps.IdentityChallengeQuestion:
        return (
          <IdentityChallengeQuestion
            checkoutData={checkoutData}
            onCheckoutDataChange={setCheckoutData}
          />
        );
      case CheckoutComps.RequiredFirstDraw:
        return (
          <RequiredFirstDraw
            checkoutData={checkoutData}
            onCheckoutDataChange={setCheckoutData}
            initialValue={0}
          />
        );
      case CheckoutComps.TermsAndAgreements:
        return (
          <TermsAndAgreements
            application={application}
            checkoutData={checkoutData}
            onCheckoutDataChange={setCheckoutData}
          />
        );
      case CheckoutComps.CheckoutSuccess:
        return <CheckoutSuccess checkoutData={checkoutData} />;
      case CheckoutComps.UnableToProceed:
        return <UnableToProceed />;
      case CheckoutComps.OffRamp:
        return <OffRamp />;
      case CheckoutComps.UnableToConfirmIdentity:
        return <UnableToConfirmIdentity />;
    }
  };

  return isLoading ? (
    <LoadingSpinner>
      <FormattedMessage id="common.loading.message" />
    </LoadingSpinner>
  ) : (
    <>
      <SteppedProgressBar
        className="mb-4"
        step={
          checkoutData.uxComponentRef &&
          CheckoutMappingStep[checkoutData.uxComponentRef][
            checkoutMappingStepType()
          ]
        }
        messages={breadcrumbsMessages()}
      />
      {renderComponent(checkoutData.uxComponentRef)}
    </>
  );
};

export default CheckoutPage;
