import styled from 'styled-components';
import { defaultBreakpoints, getMediaQuery } from '@ondeck/silkworm';
import SuccessBG from 'assets/success_bg.svg';

const mQ = getMediaQuery(defaultBreakpoints);

export const TooltipWrapper = styled.span`
  padding-left: 3px;
  position: relative;
  bottom: 3px;
`;

export const Wrapper = styled.div`
  --bg-image: url(${SuccessBG});
  background: none no-repeat center;
  display: flex;
  margin: var(--bigSpace) auto var(--bigSpace);
  max-width: var(--maxContentBox);
  text-align: center;

  div {
    padding: 0 var(--mediumSpace);
  }

  h2 {
    color: var(--backgroundColorsProgressBar);
    font-family: var(--fontMedium);
    font-size: 28px;
    font-weight: 500;
  }

  ${mQ.md`
    background-image: var(--bg-image);
    height: 380px;
    justify-content: center;
    padding-left: 150px;
    margin: var(--macroSpace) auto var(--microSpace);
    
    div {
      max-width: 425px;
    }

    h2 {
      margin-top: 75px;
    }
  `}
`;
