import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from '@ondeck/silkworm';
import Logo from 'assets/logo-color.svg';

const HeaderNav = styled.nav`
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
  min-width: 300px;
`;

type OnDeckHeaderProps = {
  className?: string;
  rightSide?: ReactNode;
  to?: string;
  href?: string;
  target?: string;
  logoAnalytics?: Function;
};

/**
 * Provides the ability to access profile view and have ondeck branding
 * for a streamlined experience from Portal to Application.
 * @prop {string} [classname] CSS class list to be passed to parent of OnDeckHeaderProps
 * @prop {string} [to] URL to be redirected in the application router
 * @prop {string} [href] URL to be redirected outside the application
 * @prop {string} [target] If href is sent the target will say where the URL will be opened
 * @prop {ReactNode} [rightSide] Component to reference User Information/menu
 * @prop {Function} [logoAnalytics]
 */
export const OnDeckHeader = ({
  className,
  to,
  href,
  target,
  rightSide,
  logoAnalytics
}: OnDeckHeaderProps) => (
  <HeaderNav className={className} aria-label="OnDeck Header">
    <Link
      to={to}
      href={href}
      target={target}
      aria-label="Go to Home page"
      onClick={logoAnalytics}
    >
      <img
        aria-label="Logo:Svg"
        src={Logo}
        alt="onDeck"
        style={{ width: 126, height: 27 }}
      />
    </Link>
    {rightSide}
  </HeaderNav>
);
