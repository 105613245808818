import React from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';
import {
  Margin,
  Header,
  WrapperAgreements,
  StyledParagraph,
  StyledHr
} from 'pages/styles/common';
import { getMediaQuery, defaultBreakpoints } from '@ondeck/silkworm';
// generated from /frontend/scripts
import lastModifiedDate from 'pages/generated-dates/esign-agreement-date';

const mQ = getMediaQuery(defaultBreakpoints);

const StyledTitle = styled.h4`
  font-size: 1.25rem;
  line-height: 1.875rem;
  margin-bottom: 0.625rem;
  margin-top: 1.25rem;
  font-family: GalanoGrotesque-Medium;

  ${mQ.md`
    font-size: 1.5625rem;
    line-height: 2.5rem;
  `}
`;

const MiddleWeightSpan = styled.span`
  font-weight: 400;
  line-height: 1.375em;
  font-size: 1rem;
  color: #3c3c3c;
`;

const StyledTable = styled.table`
  border: 1px solid grey;
  tr,
  td {
    border: 1px solid grey;
  }
  td {
    padding: 9px 10px;
    text-align: left;
  }
`;

const EsignAgreementPage = () => (
  <Margin>
    <WrapperAgreements data-testid="Wrapper:Agreements">
      <Header data-testid="DateHeader:StyledDiv">
        Updated and effective{' '}
        <FormattedDate
          value={lastModifiedDate}
          year="numeric"
          month="long"
          day="2-digit"
        />
      </Header>
      <StyledTitle>E-Sign Consent Agreement</StyledTitle>
      <StyledHr />
      <StyledParagraph>
        By clicking the check box next to the E-Sign Consent Agreement, you are
        accepting all of the following terms and conditions relating to the
        electronic notification/communications policy of On Deck Capital, Inc.,
        ODK Capital, LLC, and any affiliates doing business under the On Deck
        name (collectively &quot;OnDeck&quot;) and OnDeck’s electronic consent
        and contract binding policy. Please read the information below carefully
        and thoroughly.
      </StyledParagraph>
      <StyledParagraph>
        YOU AGREE TO ELECTRONIC CONTRACT BINDING. You consent that your
        electronic signature on agreements and documents has the same legal and
        moral effect as if you signed such agreements and documents in ink and
        will be deemed valid, authentic, enforceable and binding. You understand
        and acknowledge that the federal Electronic Signatures in Global and
        National Commerce Act defines an “electronic signature” as an electronic
        sound, symbol or process, attached to or logically associated with a
        contract or other record and executed or adopted by a person with the
        intent to be bound by such contract or record. Based on this definition,
        you further consent that your electronic actions or your participation
        in certain electronic processes that are logically associated with a
        contract or any fully disclosed terms and conditions shall also have the
        same legal effect as if you signed such contract or agreed to such terms
        and conditions by providing your written signature in ink and you agree
        that such actions/participation will be deemed a valid and binding
        contract to the extent such actions/participation indicates your intent
        to be legally bound.
      </StyledParagraph>
      <StyledParagraph>
        YOU AGREE TO ELECTRONIC COMMUNICATIONS. You hereby agree that OnDeck may
        provide any communication or notification to you electronically and you
        hereby consent to receive by electronic means any and all agreements,
        documents, disclosures and/or notices required to be given by applicable
        law, regulation or internal OnDeck policy. You also consent to allow
        OnDeck to respond to any inquiries or communications by e-mail, fax or
        other electronic means regardless of the format of the original inquiry.
        You agree that electronic copies of communications are valid and you
        will not contest the validity or enforceability of such communications
        or any related transactions, absent proof of altered data or tampering.
        Pursuant to law and regulations, you agree and acknowledge that all
        electronic communications delivered to you by OnDeck (i) shall be given
        the same legal effect as signed paper communications, (ii) shall be
        considered a “writing” or “in writing” and (iii) shall be deemed for all
        purposes to have been “signed” and to constitute an “original” when
        printed from electronic files or records established and maintained in
        the normal course of business. You agree that all electronic
        communications and actions recorded by OnDeck shall be deemed valid and
        admissible originals.
      </StyledParagraph>
      <StyledParagraph>
        YOU UNDERSTAND THE TECHNICAL REQUIREMENTS. To electronically receive,
        view, and save electronic communications and electronically signed
        agreements, you must have a personal computer equipped with the
        requirements set forth below, and either a printer, a hard drive, or
        other storage device. You must also have a valid email address. You must
        notify us if your email address changes and you hereby agree to notify
        us of any such change immediately. To notify us of your new email
        address, please log on to ondeck.com and the applicable subdomains and
        update your email address in the “My Profile” section of the website.
        Please note that the minimum requirements set forth below are subject to
        change.
      </StyledParagraph>
      <StyledParagraph>
        <MiddleWeightSpan>Required hardware and software</MiddleWeightSpan>
      </StyledParagraph>
      <StyledTable cellSpacing="0" cellPadding="5">
        <tbody>
          <tr>
            <td>
              <StyledParagraph>Operating Systems:</StyledParagraph>
            </td>
            <td>
              <StyledParagraph>
                Windows® 2000, Windows® XP, Windows Vista®; Mac OS® X
              </StyledParagraph>
            </td>
          </tr>
          <tr>
            <td>
              <StyledParagraph>Browsers:</StyledParagraph>
            </td>
            <td>
              <StyledParagraph>
                Final release versions of Internet Explorer® 6.0 or above
                (Windows only); Mozilla Firefox 2.0 or above (Windows and Mac);
                Safari™ 3.0 or above (Mac only)
              </StyledParagraph>
            </td>
          </tr>
          <tr>
            <td>
              <StyledParagraph>PDF Reader:</StyledParagraph>
            </td>
            <td>
              <StyledParagraph>
                Acrobat® or similar software may be required to view and print
                PDF files
              </StyledParagraph>
            </td>
          </tr>
          <tr>
            <td>
              <StyledParagraph>Screen Resolution:</StyledParagraph>
            </td>
            <td>
              <StyledParagraph>800 x 600 minimum</StyledParagraph>
            </td>
          </tr>
          <tr>
            <td>
              <StyledParagraph>Enabled Security Settings:</StyledParagraph>
            </td>
            <td>
              <StyledParagraph>Allow per session cookies</StyledParagraph>
            </td>
          </tr>
        </tbody>
      </StyledTable>
      <StyledParagraph> &nbsp; </StyledParagraph>
      <StyledParagraph>
        YOU UNDERSTAND THE DISCLOSURES ABOUT CHANGING YOUR CONSENT. If you elect
        to receive required notices and disclosures only in paper format, it may
        slow the speed at which we can complete certain steps in transactions
        with you and delivering services to you. Nonetheless, you may change
        your consent at any time. Your consent to electronic communications and
        E-signing may also be withdrawn at any time by providing us with written
        notice that has been notarized and sent to the address below.
        Notwithstanding your change of consent, any electronic communications
        provided or agreements entered into with your electronic signature prior
        to your consent being withdrawn shall remain effective and binding.
      </StyledParagraph>
      <StyledParagraph>
        OnDeck <br />
        E-Sign Department <br />
        901 N Stuart St, Suite 700 <br />
        Arlington, VA 22203 <br />
      </StyledParagraph>
      <StyledParagraph>
        Your correspondence must contain in the body of such request your e-mail
        address, full name, US Postal address, and telephone number. After your
        withdrawal of consent has been received and processed by OnDeck, all
        subsequent notifications and communications will be sent by regular mail
        to the last known address on file with OnDeck. You have the option to
        receive any information that we have provided electronically in paper
        form at no cost to you by providing a written request to the address
        above. Additionally, you will have the ability to download and print
        certain documents through the OnDeck Online system.
      </StyledParagraph>
      <StyledParagraph>
        YOU ACKNOWLEDGE AND CONFIRM YOUR CONSENT. If you consent to receiving
        notices and disclosures in electronic format on the terms and conditions
        described above, please let us know by clicking the check box next to
        “E-Sign Consent Agreement” on the previous page. By clicking the check
        box, you acknowledge and confirm that (1) you can access and read this
        E-SIGN CONSENT AGREEMENT; (2) you can print on paper the disclosure or
        save or send the disclosure to a place where you can print it, for
        future reference and access; and (3) until or unless you notify OnDeck
        as described above, you consent to receive from and through electronic
        means all notices, disclosures, authorizations, acknowledgements, and
        other documents that are required to be provided or made available to
        you by OnDeck during the course of your relationship with OnDeck.
      </StyledParagraph>
      <br />
      <br />
    </WrapperAgreements>
  </Margin>
);

export default EsignAgreementPage;
