import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FooterContainer,
  AlertBadge,
  Questionnaire,
  useErrorHandler,
  LoadingSpinner,
  ConfirmInformation
} from '@ondeck/silkworm';
import {
  FooterColumn,
  IdentityContainer,
  VerifyIdentityButton
} from 'pages/styles/common';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import {
  getIdentityVerificationQuestions,
  postNextState,
  submitIdentityVerificationAnswers
} from 'service';
import { Checkout } from 'states/checkoutState';
import {
  IdentityVerificationAnswer,
  IdentityVerificationAnswerData,
  Question,
  QuestionList
} from 'states/identityVerificationState';
import { useAuth } from '../../contexts/AuthContext';
import { trackOptimizelyEvent } from 'pages/helpers/helpers';

const StyledVerifyIdentity = styled(p => <ConfirmInformation {...p} />)`
  .ConfirmationCardstyle__CardHeader-qsCOe {
    width: 62%;
  }

  @media (max-width: 500px) {
    .ConfirmationCardstyle__CardHeader-qsCOe {
      width: 80%;
    }
  }
`;

const ReviewItemsContainer = styled.div`
  position: relative;
  right: 2px;

  span {
    color: #cb1c3c;
  }

  @media (max-width: 500px) {
    right: 1px;
  }
`;

interface IdentityVerificationProps {
  checkoutData: Checkout;
  onCheckoutDataChange(checkoutData: object): void;
}

const IdentityVerification = ({
  checkoutData,
  onCheckoutDataChange
}: IdentityVerificationProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [questionsData, setQuestionsData] = useState<QuestionList | any>({});
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const { addCredentials } = useAuth();
  const { handleError } = useErrorHandler();
  const intl = useIntl();

  useEffect(() => {
    (async () => {
      try {
        const response = await getIdentityVerificationQuestions(
          addCredentials,
          checkoutData.checkoutUuid
        );
        setQuestionsData(response);
      } catch (e) {
        handleError(e);
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatQuestionType = (message: string) => message.split('.').join('');

  const submitForm = async selectedAnswers => {
    setIsLoading(true);
    try {
      const payload: IdentityVerificationAnswerData = {
        idNumber: questionsData.idNumber,
        answers: []
      };
      const answers: IdentityVerificationAnswer[] = [];

      for (const answer in selectedAnswers) {
        questionsData.questions.forEach((question: Question) => {
          if (answer === formatQuestionType(question.type)) {
            answers.push({
              answer: selectedAnswers[answer],
              type: question.type
            });
          }
        });
      }
      payload.answers = answers;

      await submitIdentityVerificationAnswers(
        addCredentials,
        checkoutData.checkoutUuid,
        payload
      );
      localStorage.setItem('idNumber', questionsData.idNumber);
      trackOptimizelyEvent(addCredentials, 'confirm_identity');
      const checkoutDataRes = await postNextState(
        addCredentials,
        checkoutData.checkoutUuid,
        checkoutData.offerUuid,
        checkoutData.uxComponentRef
      );
      onCheckoutDataChange(checkoutDataRes);
    } catch (e) {
      handleError(e);
    }
    setIsLoading(false);
  };

  return isLoading ? (
    <LoadingSpinner>
      <FormattedMessage id="common.loading.message" />
    </LoadingSpinner>
  ) : (
    <form id="verifyIdentity" onSubmit={handleSubmit(submitForm)}>
      <StyledVerifyIdentity
        title={intl.formatMessage({
          id: 'verification.title'
        })}
        subhead={intl.formatMessage({
          id: 'verification.subtitle'
        })}
        content={
          <>
            <IdentityContainer>
              {questionsData.questions?.map((question: Question) => (
                <Questionnaire
                  key={question.prompt}
                  prompt={question.prompt}
                  answers={question.answerList}
                  flexDirection="column"
                  register={register(formatQuestionType(question.type), {
                    required: true
                  })}
                  name={question.type}
                  error={errors[formatQuestionType(question.type)]}
                />
              ))}
            </IdentityContainer>

            {Object.keys(errors).length > 0 && (
              <ReviewItemsContainer>
                <AlertBadge
                  alertLevel="high"
                  role="alert"
                  copy="Please review the items above"
                  minWidth="200px"
                  align="left"
                />
              </ReviewItemsContainer>
            )}
          </>
        }
        footer={
          <FooterColumn>
            <FooterContainer>
              <VerifyIdentityButton type="submit" form="verifyIdentity">
                <FormattedMessage id="verification.button" />
              </VerifyIdentityButton>
            </FooterContainer>
          </FooterColumn>
        }
      />
    </form>
  );
};

export default IdentityVerification;
