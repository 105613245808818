import React, { ReactText } from 'react';
import NumberFormat from 'react-number-format';
import { useIntl } from 'react-intl';
import Decimal from 'decimal.js';
import { Application } from '../../states/applicationState';
import {
  getApplication,
  getParties,
  getPlaidMerchantStatus,
  isInstitutionSupported,
  optimizelyTrack
} from 'service';

export const messages_loc_renewal = [
  { id: 'progressBar.offerPresentment' },
  { id: 'progressBar.confirmBankAccount' },
  { id: 'progressBar.identityVerification' },
  { id: 'progressBar.selectFirstDrawAmount' },
  { id: 'progressBar.termsAndAgreements' },
  { id: 'progressBar.checkoutSuccess' }
];

export const messages_loc_new = [
  { id: 'progressBar.offerPresentment' },
  { id: 'progressBar.addBankAccount' },
  { id: 'progressBar.identityVerification' },
  { id: 'progressBar.selectFirstDrawAmount' },
  { id: 'progressBar.termsAndAgreements' },
  { id: 'progressBar.checkoutSuccess' }
];

export const messages_tl_renewal = [
  { id: 'progressBar.offerPresentment' },
  { id: 'progressBar.confirmBankAccount' },
  { id: 'progressBar.identityVerification' },
  { id: 'progressBar.termsAndAgreements' },
  { id: 'progressBar.checkoutSuccess' }
];

export const messages_tl_new = [
  { id: 'progressBar.offerPresentment' },
  { id: 'progressBar.addBankAccount' },
  { id: 'progressBar.identityVerification' },
  { id: 'progressBar.termsAndAgreements' },
  { id: 'progressBar.checkoutSuccess' }
];

export const parseIntegerRender = (num: string | number) => {
  let value = typeof num === 'number' ? num.toString() : num;

  const val = value.toString().split('.');
  return (
    <h3>
      {val[0]}
      {val[1] && val[1] !== '00' ? (
        <>{`.${val[1]}`}</>
      ) : (
        <sup data-testid="Sup:DecimalValue" style={{ fontSize: '16px' }}>
          {val[1]}
        </sup>
      )}
    </h3>
  );
};

export const h3Render = (value: ReactText) => <h3>{value}</h3>;

export const formatNumberCompose = (
  value: string | number,
  renderText?: React.ReactNode | undefined,
  prefix: string | undefined = '$',
  suffix: string | undefined = '',
  decimalScale?: number | undefined
) => {
  value = value.toString();

  let renderVal: (value: string) => React.ReactNode = value => value;

  if (renderText === 'h3') {
    renderVal = (value: string) => h3Render(value);
  } else if (renderText === 'int') {
    renderVal = (value: string) => parseIntegerRender(value);
  }

  return (
    <NumberFormat
      value={value}
      prefix={prefix}
      suffix={suffix}
      decimalScale={decimalScale}
      renderText={value => renderVal(value)}
      fixedDecimalScale
      displayType="text"
      thousandSeparator={true}
    />
  );
};

export const FormatPhoneNumber = ({ value }: { value?: string | number }) => {
  value = value?.toString();

  const renderVal: (value: string) => React.ReactNode = value => value;
  const intl = useIntl();
  return value ? (
    <NumberFormat
      value={value}
      renderText={value => renderVal(value)}
      displayType="text"
      format={intl.formatMessage({ id: 'common.numberFormat' })}
    />
  ) : (
    <></>
  );
};

export const titleCase = (message: string) => {
  const splittedComma = message.split(',');
  const words = splittedComma[0].split(' ').map(word => word.toLowerCase());
  let titleCasedMessage = '';

  for (let i = 0; i < words.length; i++) {
    let word = words[i];
    titleCasedMessage +=
      word === 'of'
        ? `${word} `
        : `${word.charAt(0).toUpperCase()}${word.slice(1)} `;
  }

  titleCasedMessage = titleCasedMessage.trimEnd();

  if (splittedComma[1]) {
    titleCasedMessage += `, ${splittedComma[1].trimStart()}`;
  }

  return titleCasedMessage;
};

export const codToCents = (cod: number) =>
  cod === 0
    ? 0
    : Number(new Decimal(cod).sub(1).mul(100).toDP(1, Decimal.ROUND_HALF_UP));

export const isStateRegSpecific = (application: Application): boolean =>
  application.data.party.businesses.every(business =>
    business.addresses.some(
      address => address.state === 'CA' || address.state === 'NY'
    )
  );

export const calculatedEstimatedPayment = requestedDollarData => {
  const {
    inputSliderValue,
    apr,
    paymentFrequency,
    term,
    numberOfPeriods
  } = requestedDollarData;

  const selectedValue = inputSliderValue == null ? 1000 : inputSliderValue;

  const paymentsInYear = paymentFrequency === 'WEEKLY' ? 52 : 12;

  const paymentsNoByTerm = Math.ceil((parseInt(term) * paymentsInYear) / 12);
  const paymentsNo = numberOfPeriods ? numberOfPeriods : paymentsNoByTerm;

  const periodInterest = apr / 100 / paymentsInYear;

  const one_plus_i_to_nth = Math.pow(1 + periodInterest, parseInt(paymentsNo));

  const derivative =
    (periodInterest * one_plus_i_to_nth) / (one_plus_i_to_nth - 1);

  const estimatedPaymentAmount = selectedValue * derivative;
  return Math.round(estimatedPaymentAmount * 100) / 100;
};

export const isPlaidConnected = async (addCredentials: any) => {
  const applicationResponse = await getApplication(addCredentials);
  const opportunityId = applicationResponse?.metadata?.sfOpportunityId || '';
  const plaidMerchantStatus = await getPlaidMerchantStatus(
    addCredentials,
    opportunityId
  );
  return plaidMerchantStatus?.plaidConnected;
};

export const isInstitutionSupportedByPlaid = async (
  addCredentials: any,
  routingNumber: any
) => {
  const institutionSupported = await isInstitutionSupported(
    addCredentials,
    routingNumber
  );
  return institutionSupported?.institutionSupported;
};

export const showPlaidPreAgreementFlow = async (
  addCredentials: any,
  routingNumber: any,
  application: Application
) => {
  const partiesData = await getParties(addCredentials);
  const plaidConnected = await isPlaidConnected(addCredentials);
  const verifyConnectionAndSupport =
    !plaidConnected &&
    (await isInstitutionSupportedByPlaid(addCredentials, routingNumber));
  const showPlaid =
    application?.metadata?.channelCode === 'DIRECT' &&
    partiesData?.optimizely?.plaidAdoptionVariationKey ===
      'pre_agreement_flow' &&
    verifyConnectionAndSupport;

  return {
    showPlaid,
    skip_plaid_enabled:
      partiesData?.optimizely?.plaidPreAgreementVariationKey === 'skip_enabled'
  };
};

export const trackOptimizelyEvent = async (
  addCredentials: any,
  eventKey: string,
  attributes?: any
) => {
  const partiesData = await getParties(addCredentials);
  await optimizelyTrack(addCredentials, partiesData?.user?.id, {
    eventKey: eventKey,
    attributes: attributes
  });
};
