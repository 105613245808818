import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useTracking } from 'react-tracking';
import { PhoneLink, UserAvatar, useErrorHandler } from '@ondeck/silkworm';
import {
  MainContent,
  PageWrapper,
  StyledFooter,
  StyledButton
} from './AppContent.style';
import { OnDeckHeader } from '../common/OnDeckHeader';
import TechnicalDifficultiesPage from 'pages/TechnicalDifficultiesPage';
import { useAuth } from '../../contexts/AuthContext';
import { useUserData } from '../../contexts/UserContext';

interface ContentProps {
  children?: React.ReactNode;
  hideSignOut: boolean;
}

const AppContent = ({ children, hideSignOut }: ContentProps) => {
  const intl = useIntl();
  const { hasError } = useErrorHandler();
  const { trackEvent } = useTracking();
  const { userName } = useUserData();
  const { isAuthenticated, logout } = useAuth();

  return (
    <PageWrapper>
      <MainContent className="px-3 pb-4 w-100 m-auto">
        <OnDeckHeader
          className="py-5"
          rightSide={
            isAuthenticated && (
              <div>
                {userName && (
                  <div>
                    <UserAvatar name={userName} small />
                  </div>
                )}
                {!hasError && !hideSignOut && (
                  <StyledButton
                    testId="Button::SignOut"
                    onClick={() => logout(window.env.REACT_APP_LOGIN_PAGE_URL)}
                    emphasis="none"
                  >
                    <FormattedMessage id="appContent.button.signOut" />
                  </StyledButton>
                )}
              </div>
            )
          }
          href={window.env.REACT_APP_BAZAAR_URL}
          target="_self"
          logoAnalytics={() => {
            trackEvent({ event: 'Logo Clicked' });
          }}
        />
        {hasError ? <TechnicalDifficultiesPage /> : <>{children}</>}
      </MainContent>
      <StyledFooter
        hours={intl.formatMessage({ id: 'footer.hours' })}
        phone={
          <PhoneLink
            phoneNumber={intl.formatMessage({ id: 'common.contactNumber' })}
          />
        }
        privacyHref="https://www.ondeck.com/privacy"
        privacyLink="Privacy Policy"
        securityHref="https://www.ondeck.com/security-policy"
        securityLink="Security Policy"
      >
        <p style={{ marginTop: '10px' }}>
          <FormattedMessage id="footer.body" />
        </p>
        <p>
          <FormattedMessage id="footer.copyright" />
        </p>
      </StyledFooter>
    </PageWrapper>
  );
};

export default AppContent;
