import React from 'react';
import NumberFormat from 'react-number-format';

interface MoneyProps {
  value: number | string;
  cents?: boolean;
  variant?: string;
  className?: string;
  decimalScale?: number;
  toFixed?: boolean;
  format?: 'dollar' | 'cents';
  oneDecimalFormat?: boolean;
}

export const MoneyFormat = ({
  value,
  variant = 'text',
  className = '',
  cents = true,
  decimalScale = -1,
  toFixed = true,
  format = 'dollar',
  oneDecimalFormat = false
}: MoneyProps) => {
  const computedDecimalScale = () => {
    if (cents || decimalScale > 0) return decimalScale > 0 ? decimalScale : 2;
    else return 0;
  };
  return (
    <NumberFormat
      className={`text-${variant} ${className}`}
      value={value}
      displayType="text"
      prefix={format === 'dollar' ? '$' : ''}
      suffix={format === 'cents' ? '¢' : ''}
      decimalScale={oneDecimalFormat ? 1 : computedDecimalScale()}
      thousandSeparator={true}
      fixedDecimalScale={toFixed}
    />
  );
};

interface DecimalProps {
  value: number | string;
  variant?: string;
  className?: string;
  round?: boolean;
  decimalScale?: number;
  toFixed?: boolean;
  suffix?: string;
  prefix?: string;
}

export const DecimalFormat = ({
  value,
  variant = 'text',
  className = '',
  round = true,
  decimalScale = 2,
  toFixed = false,
  suffix = '',
  prefix = ''
}: DecimalProps) => {
  return (
    <NumberFormat
      className={`text-${variant} ${className}`}
      value={value}
      displayType="text"
      thousandSeparator={true}
      decimalScale={round ? decimalScale : 10}
      suffix={suffix}
      prefix={prefix}
      fixedDecimalScale={toFixed}
    />
  );
};

interface IntegerProps {
  value: number | string;
  variant?: string;
  className?: string;
  suffix?: string;
  prefix?: string;
}

export const IntegerFormat = ({
  value,
  variant = 'text',
  className = '',
  suffix = '',
  prefix = ''
}: IntegerProps) => {
  return (
    <NumberFormat
      className={`text-${variant} ${className}`}
      value={value}
      displayType="text"
      thousandSeparator={true}
      suffix={suffix}
      prefix={prefix}
    />
  );
};
