export interface Offer {
  readonly totalLoanCost: number;
  readonly disbursementAmt: number;
  readonly trueAPR: number;
  readonly trueCentsOnDollar: number;
  readonly paymentFrequency: string;
  readonly averageMonthlyPayment: number;
  readonly loanOriginationFee: number;
  readonly totalAmountPaidBack: number;
  readonly lineAmount: number;
  readonly payment: number;
  readonly term: number;
  readonly product: string;
  readonly centsOnDollar: number;
  readonly lateFee: number;
  readonly requiredInitialDraw: number;
  readonly monthlyFee: number;
  readonly returnedPaymentFee: number;
  readonly prePaymentDiscountPct: number | null;
  readonly numberOfPayments?: number | null;
  readonly remainingPrincipal?: number | null;
}

export const offerInitialState: Offer = {
  totalLoanCost: 0,
  disbursementAmt: 0,
  trueAPR: 59.9,
  trueCentsOnDollar: 0,
  paymentFrequency: 'WEEKLY',
  averageMonthlyPayment: 0,
  loanOriginationFee: 0,
  totalAmountPaidBack: 0,
  lineAmount: 10000,
  payment: 0,
  term: 12,
  product: '',
  centsOnDollar: 0,
  lateFee: 0,
  requiredInitialDraw: 1000,
  monthlyFee: 0,
  returnedPaymentFee: 0,
  prePaymentDiscountPct: 0,
  numberOfPayments: 52,
  remainingPrincipal: 1000
};
