interface Contact {
  type: 'PHONE_NUMBER' | 'HOME_PHONE';
  value: string;
}

interface Identifier {
  type: 'TAX_ID';
  value: string;
}

interface Address {
  lineOne: string;
  lineTwo: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface Business {
  type: 'APPLICANT_BUSINESS';
  addresses: Address[];
  contacts: Contact[];
  identifiers: Identifier[];
  doingBusinessAs: string;
  inceptionDate: string;
  legalEntityType: string;
  legalName: string;
  stateOfIncorporation: string;
}

export interface Person {
  type: 'OWNER';
  addresses: Address[];
  contacts: Contact[];
  identifiers: Identifier[];
  firstName: string;
  lastName: string;
  typeData: {
    ownershipPct: number;
  };
}

export interface Party {
  businesses: Business[];
  persons: Person[];
}

export interface Loan {
  loanNumber: string;
}

export interface Application {
  id: string;
  type: 'NEW' | 'RENEWAL' | undefined;
  data: {
    party: Party;
    version: number;
    loan: Loan;
  };
  metadata: {
    channelCode: string;
  };
}

export const applicationInitialState: Application = {
  id: '',
  type: undefined,
  data: {
    party: {
      businesses: [],
      persons: []
    },
    loan: {
      loanNumber: ''
    },
    version: 0
  },
  metadata: {
    channelCode: 'DIRECT'
  }
};
