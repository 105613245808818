import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { MoneyInput } from './MoneyInput';
import { SimpleSlider } from './SimpleSlider';
import { MoneyFormat } from 'components/TextFormat';

interface InputSliderComboProps {
  min?: number;
  max?: number;
  size?: 'sm' | 'lg' | undefined;
  initialValue?: number;
  onChange?: Function;
  onAlert?: Function;
  sm?: number;
}

const defaultChoice = (min, max) => Math.round((75 * (max - min)) / 100 + min);
export const InputSliderCombo = ({
  min = 5000,
  max = 10000000,
  initialValue,
  size = 'lg',
  sm = 8,
  onChange = () => {},
  onAlert = () => {}
}: InputSliderComboProps) => {
  const [value, setValue] = useState(
    initialValue ? initialValue : defaultChoice(min, max)
  );
  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <Row>
      <Col sm={sm}>
        <Stack
          direction="horizontal"
          className="fs-4 pt-2 mb-1"
          style={{ height: '16px' }}
        >
          <Stack direction="horizontal" gap={1}>
            Min:
            <MoneyFormat value={min} variant="text" cents={false} />
          </Stack>
          <Stack className="ms-auto" direction="horizontal" gap={1}>
            Max:
            <MoneyFormat value={max} variant="text" cents={false} />
          </Stack>
        </Stack>
        <SimpleSlider
          min={min}
          max={max}
          value={value < min ? min : value > max ? max : value}
          onChange={v => {
            setValue(v);
          }}
        />
      </Col>
      <Col className="mt-3 mt-sm-0">
        <MoneyInput
          min={min}
          max={max}
          value={value < min ? min : value > max ? max : value}
          onChange={v => {
            setValue(v);
          }}
          onAlert={onAlert}
        />
      </Col>
    </Row>
  );
};
