import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTracking } from 'react-tracking';
import { Button, GenericMessage, Link, PhoneLink } from '@ondeck/silkworm';
import { useIntl, FormattedMessage } from 'react-intl';
import Unable from 'assets/unableToConfirm.svg';

const Wrapper = styled.div`
  background-color: #f5f8fc;
  display: flex;
  font-size: var(--fontSizesButton);
  justify-content: center;
  padding: 0;
  width: 100%;
  span {
    font-size: var(--fontSizesBody);
  }
`;

const UnableToConfirmIdentity = () => {
  const intl = useIntl();
  const { trackEvent } = useTracking();
  const eventName = 'Unable To Confirm Page';
  useEffect(() => {
    trackEvent({ event: `${eventName} Loaded` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper aria-label="GenericMessage:Wrapper">
      <GenericMessage
        image={Unable}
        title={intl.formatMessage({ id: 'unableToConfirmPage.title' })}
      >
        <p>
          <FormattedMessage id="unableToConfirmPage.content" />
          <br />
          <FormattedMessage
            id="common.contactSentence"
            values={{
              Break: <br />,
              phoneLink: (
                <PhoneLink
                  phoneNumber={intl.formatMessage({
                    id: 'common.contactNumber'
                  })}
                />
              ),
              renewalsEmail: (
                <Link
                  href={`mailto:${intl.formatMessage({
                    id: 'technicalDifficulties.emailLink'
                  })}`}
                  role="link"
                >
                  <FormattedMessage id="technicalDifficulties.emailCopy" />
                </Link>
              )
            }}
          />
        </p>
        <Link href={window.env.REACT_APP_BAZAAR_URL} target="_self">
          <Button>
            <FormattedMessage id="common.button.returnHome" />
          </Button>
        </Link>
      </GenericMessage>
    </Wrapper>
  );
};

export default UnableToConfirmIdentity;
