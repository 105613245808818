import React, { createContext, useContext, useEffect, useState } from 'react';
import { getParties } from '../service';
import { DatadogSetUser } from '../analytics';
import { useAuth } from './AuthContext';

interface UserDataContext {
  userId?: string;
  userName?: string;
}

const UserDataContext = createContext<UserDataContext>({
  userId: undefined,
  userName: undefined
});

export const UserDataProvider = ({ children }) => {
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const { isAuthenticated, user, addCredentials } = useAuth();

  useEffect(() => {
    (async () => {
      if (isAuthenticated && user) {
        const userId = user.userId?.toString() || '';
        setUserId(userId);

        const partiesData = await getParties(addCredentials);
        setUserName(partiesData.user.name);

        DatadogSetUser({
          userId,
          name: partiesData.user?.name,
          email: user.email
        });
      }
    })();
  }, [user, isAuthenticated]);

  return (
    <UserDataContext.Provider
      value={{
        userId,
        userName
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
