import React, { useEffect } from 'react';
import './LoginDev.css';

/**
 * Login Page (https://git.enova.com/pages/services/login_page) component for local development.
 * Is not intended for other envs (dev, staging, prod) which use the ODO Login Page instead.
 */
const LoginDev = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = window.env.REACT_APP_LOGIN_PAGE_JS_SRC;
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="loginPage__container">
      <div className="loginPage__signInTitle">Sign In</div>
      <login-page
        id="login_page"
        captcha-key={window.env.REACT_APP_LOGIN_SERVER_CAPTCHA_KEY}
        captcha-engine="RECAPTCHA"
        client-id={window.env.REACT_APP_LOGIN_SERVER_CLIENT_ID}
        login-server-uri={window.env.REACT_APP_LOGIN_SERVER_URL}
        redirect-uri={`${window.env.REACT_APP_SPICEROAD_URL}/v1/users/oauth2redirect?location=${window.env.REACT_APP_BASE_URL}`}
        login-label="Sign In"
        forgot-password-url={`${window.env.REACT_APP_ODO_URL}/reset_password`}
        response-mode="form_post"
      ></login-page>
    </div>
  );
};

export default LoginDev;
