import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  ConfirmInformation,
  Link,
  LoadingSpinner,
  Tooltip,
  useErrorHandler
} from '@ondeck/silkworm';
import {
  Heading,
  InnerWrapper,
  StyledAnchor,
  StyledConfirmationCard,
  Subtitle,
  TooltipWrapper
} from 'components/TermsAndAgreements/TermsAndAgreements.styles';

import { Button, Card, Container } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTracking } from 'react-tracking';
import { Checkout } from 'states/checkoutState';
import { Offer, offerInitialState } from 'states/offerState';
import { Application } from 'states/applicationState';

import {
  completeApplication,
  getContract,
  getDetailedOffer,
  getDisclosure
} from 'service';
import TermLoanReview from '../TermLoanReview';
import LocReview from '../LocReview';
import { LoanDetails, loanDetailsInitialState } from 'states/loanDetailsState';
import { useAuth } from 'contexts/AuthContext';
import { trackOptimizelyEvent } from 'pages/helpers/helpers';

interface TermsAndAgreementProps {
  checkoutData: Checkout;
  isLoadingInit?: boolean;
  onCheckoutDataChange(checkoutData: object): void;
  application: Application;
}

const TermsAndAgreements = ({
  checkoutData,
  isLoadingInit = true,
  onCheckoutDataChange,
  application
}: TermsAndAgreementProps) => {
  const [isLoading, setIsLoading] = useState(isLoadingInit);
  const [disclosure, setDisclosure] = useState() as any;
  const [disclosureLinkOpened, setDisclosureLinkOpened] = useState(false);
  const [contract, setContract] = useState() as any;
  const [offerData, setOfferData] = useState<Offer>(offerInitialState);
  const [loanDetailsData, setLoanDetailsData] = useState<LoanDetails>(
    loanDetailsInitialState
  );

  //hooks
  const contractRef = useRef() as React.MutableRefObject<HTMLAnchorElement>;
  const disclosureRef = useRef() as React.MutableRefObject<HTMLAnchorElement>;
  const { isAuthenticated, addCredentials } = useAuth();
  const { handleError } = useErrorHandler();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const intl = useIntl();
  const { trackEvent } = useTracking();

  const transitionEventName = 'Terms and Agreements Page Transition';

  useEffect(() => {
    window.onpopstate = () => {
      window.location.replace(`${window.env.REACT_APP_BAZAAR_URL}`);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const offerDataResponse = await getDetailedOffer(
            addCredentials,
            checkoutData.applicationUuid,
            checkoutData.offerUuid
          );
          setOfferData(offerDataResponse);
          setLoanDetailsData(offerDataResponse);
          const contractRes = await getContract(
            addCredentials,
            checkoutData.checkoutUuid
          );
          setContract(contractRes);
          if (!!checkoutData.disclosureUuid) {
            const disclosureRes = await getDisclosure(
              addCredentials,
              checkoutData.checkoutUuid
            );
            setDisclosure(disclosureRes);
          }
        } catch (e) {
          handleError(e);
        }

        setIsLoading(false);

        trackEvent({ event: 'Terms and Agreements Page Loaded' });
        trackEvent({
          isTimed: true,
          event: transitionEventName
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, application]);

  const handleContractDownload = async () => {
    if (contractRef.current.href) {
      return;
    }

    trackEvent({
      event: transitionEventName,
      link: 'Contract'
    });

    const contract_blob = await contract.blob();

    return URL.createObjectURL(contract_blob);
  };

  const handleDisclosureDownload = async () => {
    if (disclosureRef.current.href) {
      return;
    }

    trackEvent({
      event: transitionEventName,
      link: 'Disclosure'
    });

    const disclosure_blob = await disclosure.blob();

    setDisclosureLinkOpened(true);
    clearErrors('disclosureLink');
    clearErrors('disclosureCheckbox');

    return URL.createObjectURL(disclosure_blob);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const checkoutDataRes = await completeApplication(
        addCredentials,
        checkoutData.checkoutUuid,
        checkoutData.uxComponentRef
      );
      onCheckoutDataChange(checkoutDataRes);
    } catch (e) {
      handleError(e);
    }
    setIsLoading(false);
    trackEvent({ event: transitionEventName, button: 'Agree and get funds' });
    trackOptimizelyEvent(addCredentials, 'submit_agreement');
  };

  const checkDisclosureOpened = () => {
    if (!disclosureLinkOpened)
      setError('disclosureCheckbox', {
        type: 'custom',
        message: 'Disclosure not opened'
      });
  };

  return isLoading ? (
    <LoadingSpinner>
      <FormattedMessage id="common.loading.message" />
    </LoadingSpinner>
  ) : (
    <>
      {offerData.product === 'LineOfCredit' ? (
        <span data-testid="termsAndAgreements-loaded">
          <LocReview
            checkoutData={checkoutData}
            offerData={offerData}
            application={application}
          />
        </span>
      ) : (
        <ConfirmInformation
          title={intl.formatMessage({ id: 'termsAndAgreements.title' })}
          content={
            <InnerWrapper data-testid="termsAndAgreements-loaded">
              <Subtitle>
                <FormattedMessage id="termsAndAgreements.subtitle" />{' '}
                <TooltipWrapper>
                  <Tooltip>
                    <FormattedMessage id="common.toolTip.sameDayFunding" />
                  </Tooltip>
                </TooltipWrapper>
              </Subtitle>
              <TermLoanReview
                offerData={offerData}
                application={application}
                loanDetailsData={loanDetailsData}
              />
            </InnerWrapper>
          }
        />
      )}

      {!!checkoutData.disclosureUuid && (
        <Card className="mt-5 p-md-5 py-3 customCard shadow">
          <div className="px-4">
            <div className="fs-3 fw-bold">
              <FormattedMessage id="termsAndAgreements.disclosureTitle" />
            </div>
            <div>
              <FormattedMessage id="termsAndAgreements.disclosureInstructions" />
            </div>
            <div className="mt-3 form-check">
              <input
                className="mt-1 form-check-input"
                type="checkbox"
                id="disclosureCheckbox"
                aria-invalid={errors.disclosureCheckbox ? 'true' : 'false'}
                onClick={checkDisclosureOpened}
                {...register('disclosureCheckbox', { required: true })}
              />
              <label
                className=" form-check-label fs-5"
                htmlFor="disclosureCheckbox"
              >
                <FormattedMessage
                  id="termsAndAgreements.disclosureCheckbox"
                  values={{
                    disclosureLink: (
                      <>
                        <input
                          className="d-none"
                          type="radio"
                          id="disclosureLink"
                          checked={disclosureLinkOpened}
                          {...register('disclosureLink', {
                            required: true
                          })}
                        />
                        <label
                          className="fs-5 form-check-label"
                          id="disclosureLabel"
                          htmlFor="disclosureLink"
                          onClick={e => {
                            e.preventDefault();
                            handleDisclosureDownload().then(url => {
                              window.open(url, '_blank');
                            });
                          }}
                        >
                          <StyledAnchor className="fs-5" ref={disclosureRef}>
                            {offerData.product === 'LineOfCredit'
                              ? intl.formatMessage({
                                  id: 'termsAndAgreements.disclosureLinkLoc'
                                })
                              : intl.formatMessage({
                                  id: 'termsAndAgreements.disclosureLinkTerm'
                                })}
                          </StyledAnchor>
                        </label>
                      </>
                    )
                  }}
                />
              </label>
              {(errors.disclosureCheckbox || errors.disclosureLink) && (
                <div className="alert alert-danger mt-2 fs-5" role="alert">
                  <FormattedMessage id="termsAndAgreements.disclosureErrorMessage" />
                </div>
              )}
            </div>
          </div>
        </Card>
      )}

      <StyledConfirmationCard className="mt-5">
        <Heading>
          <h3>
            {offerData.product === 'LineOfCredit' ? (
              <FormattedMessage id="termsAndAgreements.agreementHeadingLOC" />
            ) : (
              <FormattedMessage id="termsAndAgreements.agreementHeading" />
            )}
          </h3>
          <p>
            <FormattedMessage id="termsAndAgreements.agreementInstructions" />
          </p>
          <StyledAnchor
            className="d-block mb-3"
            ref={contractRef}
            onClick={() => {
              handleContractDownload().then(url => {
                window.open(url, '_blank');
              });
            }}
          >
            {offerData.product === 'LineOfCredit' ? (
              <FormattedMessage id="termsAndAgreements.agreementTitleLOC" />
            ) : (
              <FormattedMessage id="termsAndAgreements.agreementTitle" />
            )}
          </StyledAnchor>
        </Heading>
        <form className="form-check" onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <input
                id="firstCheckbox"
                type="checkbox"
                aria-invalid={errors.firstCheckbox ? 'true' : 'false'}
                {...register('firstCheckbox', { required: true })}
                className="mt-1 form-check-input"
              />
              <label className="fs-5 form-check-label" htmlFor="firstCheckbox">
                {offerData.product === 'LineOfCredit' ? (
                  <FormattedMessage id="termsAndAgreements.checkbox1LOC" />
                ) : (
                  <FormattedMessage id="termsAndAgreements.checkbox1" />
                )}
              </label>
              {errors.firstCheckbox && (
                <div className="alert alert-danger mt-2 fs-5" role="alert">
                  <FormattedMessage id="termsAndAgreements.checkboxErrorMessage" />
                </div>
              )}
            </li>
            <li>
              <input
                id="secondCheckbox"
                type="checkbox"
                aria-invalid={errors.secondCheckbox ? 'true' : 'false'}
                {...register('secondCheckbox', { required: true })}
                className="mt-1 form-check-input"
              />
              <label className="fs-5 form-check-label" htmlFor="secondCheckbox">
                <FormattedMessage id="termsAndAgreements.checkbox2" />
              </label>
              {errors.secondCheckbox && (
                <div className="alert alert-danger mt-2 fs-5" role="alert">
                  <FormattedMessage id="termsAndAgreements.checkboxErrorMessage" />
                </div>
              )}
            </li>
            <li>
              <input
                id="thirdCheckbox"
                type="checkbox"
                aria-invalid={errors.thirdCheckbox ? 'true' : 'false'}
                {...register('thirdCheckbox', { required: true })}
                className="mt-1 form-check-input"
              />
              <label className="fs-5 form-check-label" htmlFor="thirdCheckbox">
                <FormattedMessage
                  id="termsAndAgreements.checkbox3"
                  values={{
                    privacyLink: (
                      <Link
                        href="https://www.ondeck.com/privacy"
                        onClick={() => {
                          trackEvent({
                            event: transitionEventName,
                            link: 'Privacy Policy'
                          });
                        }}
                      >
                        <FormattedMessage id="common.privacyPolicy.label" />
                      </Link>
                    ),
                    creditCheckLink: (
                      <Link
                        href="/creditagreement"
                        onClick={() => {
                          trackEvent({
                            event: transitionEventName,
                            link: 'Credit Profile Agreement'
                          });
                        }}
                      >
                        <FormattedMessage id="common.creditCheck.label" />
                      </Link>
                    ),
                    esignConsent: (
                      <Link
                        href="/esignagreement"
                        onClick={() => {
                          trackEvent({
                            event: transitionEventName,
                            link: 'E-Sign Consent Agreement'
                          });
                        }}
                      >
                        <FormattedMessage id="common.esignConsent.label" />
                      </Link>
                    ),
                    applicationConsent: (
                      <Link
                        href="/terms"
                        onClick={() => {
                          trackEvent({
                            event: transitionEventName,
                            link: 'Application Agreement'
                          });
                        }}
                      >
                        <FormattedMessage id="common.applicationConsent.label" />
                      </Link>
                    )
                  }}
                />
              </label>
              {errors.thirdCheckbox && (
                <div className="alert alert-danger mt-2 fs-5" role="alert">
                  <FormattedMessage id="termsAndAgreements.checkboxErrorMessage" />
                </div>
              )}
            </li>
            <li>
              <input
                id="fourthCheckbox"
                type="checkbox"
                aria-invalid={errors.fourthCheckbox ? 'true' : 'false'}
                {...register('fourthCheckbox', { required: true })}
                className="mt-1 form-check-input"
              />
              <label className="fs-5 form-check-label" htmlFor="fourthCheckbox">
                <FormattedMessage id="termsAndAgreements.checkbox4" />
              </label>
              {errors.fourthCheckbox && (
                <div className="alert alert-danger mt-2 fs-5" role="alert">
                  <FormattedMessage id="termsAndAgreements.checkboxErrorMessage" />
                </div>
              )}
            </li>
          </ul>
          <Container className="p-1 align-items-center justify-content-center d-flex flex-column flex-md-row">
            <Button
              type="submit"
              className={`m-2 rounded-pill`}
              style={{ width: '220px', fontSize: '18px' }}
            >
              <FormattedMessage id="termsAndAgreements.agreeAndFunds" />
            </Button>
          </Container>
        </form>
      </StyledConfirmationCard>
    </>
  );
};

export default TermsAndAgreements;
