import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FooterContainer,
  AlertBadge,
  Questionnaire,
  useErrorHandler,
  Link,
  LoadingSpinner,
  PhoneLink,
  ConfirmInformation
} from '@ondeck/silkworm';
import {
  mQ,
  FooterColumn,
  IdentityContainer,
  VerifyIdentityButton
} from 'pages/styles/common';
import { useForm } from 'react-hook-form';
import { Checkout } from 'states/checkoutState';

import {
  Question,
  QuestionList,
  IdentityVerificationAnswer,
  IdentityVerificationAnswerData
} from 'states/identityVerificationState';
import {
  getIdentityChallengeQuestions,
  postNextState,
  submitIdentityChallengeAnswers
} from 'service';
import { useAuth } from '../../contexts/AuthContext';

const StyledChallengeQuestion = styled(p => <ConfirmInformation {...p} />)`
  .ConfirmInformationstyle__Flex-sc-imjvbk {
    width: 100%;
  }

  .ConfirmationCardstyle__CardHeader-qsCOe,
  .ConfirmInformationstyle__Title-sc-13p16y3 {
    width: 80%;
  }

  @media (max-width: 500px) {
    .ConfirmationCardstyle__CardHeader-qsCOe,
    .ConfirmInformationstyle__Title-sc-13p16y3 {
      width: 80%;
    }
  }
`;

const AdditionalAttemptContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 20px;
  position: relative;

  @media (max-width: 768px) {
    right: 10px;
  }
`;

const IdentityChallengeQuestionWrapper = styled.div`
  ${mQ.md`
    margin: 0 auto;
  `}
`;

export const ChallengeQuestionContainer = styled(IdentityContainer)`
  left: 8%;

  @media (max-width: 500px) {
    left: 25%;
  }
`;

const SubheaderText = styled.p`
  margin-bottom: 0px;
`;

interface ChallengeQuestionProps {
  checkoutData: Checkout;

  onCheckoutDataChange(checkoutData: object): void;
}

const IdentityChallengeQuestion = ({
  checkoutData,
  onCheckoutDataChange
}: ChallengeQuestionProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [questionsData, setQuestionsData] = useState<QuestionList | any>({});
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { addCredentials } = useAuth();
  const { handleError } = useErrorHandler();

  useEffect(() => {
    (async () => {
      try {
        const idNumber = localStorage.getItem('idNumber');

        if (!idNumber) {
          throw new Error('idNumber was not found');
        }

        const response = await getIdentityChallengeQuestions(
          addCredentials,
          checkoutData.checkoutUuid,
          idNumber
        );
        setQuestionsData(response);
      } catch (e) {
        handleError(e);
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async selectedAnswers => {
    setIsLoading(true);
    try {
      const idNumber = localStorage.getItem('idNumber');

      if (!idNumber) {
        throw new Error('idNumber was not found');
      }

      const payload: IdentityVerificationAnswerData = {
        idNumber,
        answers: []
      };
      const answers: IdentityVerificationAnswer[] = [];

      for (const answer in selectedAnswers) {
        questionsData.questions.forEach((question: Question) => {
          if (answer === formatQuestionType(question.type)) {
            answers.push({
              answer: selectedAnswers[answer],
              type: question.type
            });
          }
        });
      }
      payload.answers = answers;

      await submitIdentityChallengeAnswers(
        addCredentials,
        checkoutData.checkoutUuid,
        payload
      );
      localStorage.removeItem('idNumber');

      const checkoutDataRes = await postNextState(
        addCredentials,
        checkoutData.checkoutUuid,
        checkoutData.offerUuid,
        checkoutData.uxComponentRef
      );
      onCheckoutDataChange(checkoutDataRes);
    } catch (e) {
      handleError(e);
    }
    setIsLoading(false);
  };

  const intl = useIntl();

  const formatQuestionType = (message: string) => message.split('.').join('');

  const renderSubHeaderText = () => (
    <SubheaderText>
      <FormattedMessage
        id="identityChallenge.error"
        values={{
          phoneLink: (
            <PhoneLink
              phoneNumber={intl.formatMessage({
                id: 'common.contactNumber'
              })}
            />
          ),
          renewalsEmail: (
            <Link
              href={`mailto:${intl.formatMessage({
                id: 'technicalDifficulties.emailLink'
              })}`}
              role="link"
            >
              <FormattedMessage id="technicalDifficulties.emailCopy" />
            </Link>
          )
        }}
      />
    </SubheaderText>
  );

  return isLoading ? (
    <LoadingSpinner>
      <FormattedMessage id="common.loading.message" />
    </LoadingSpinner>
  ) : (
    <IdentityChallengeQuestionWrapper>
      <form id="challengeQuestion" onSubmit={handleSubmit(submitForm)}>
        <StyledChallengeQuestion
          title={intl.formatMessage({
            id: 'verification.challenge.question.title'
          })}
          subhead={
            <>
              <AdditionalAttemptContainer>
                <AlertBadge
                  alertLevel="medium"
                  role="alert"
                  copy="You have 1 more attempt left to verify your identity"
                  minWidth="200px"
                  align="center"
                  border="1px solid #FADC7A"
                />
              </AdditionalAttemptContainer>

              {renderSubHeaderText()}
            </>
          }
          content={
            <ChallengeQuestionContainer>
              {questionsData.questions?.map((question: Question) => (
                <Questionnaire
                  key={question.prompt}
                  prompt={question.prompt}
                  answers={question.answerList}
                  flexDirection="column"
                  register={register(formatQuestionType(question.type), {
                    required: true
                  })}
                  name={question.type}
                  error={errors[formatQuestionType(question.type)]}
                />
              ))}
            </ChallengeQuestionContainer>
          }
          footer={
            <FooterColumn>
              <FooterContainer>
                <VerifyIdentityButton type="submit" form="challengeQuestion">
                  <FormattedMessage id="verification.button" />
                </VerifyIdentityButton>
              </FooterContainer>
            </FooterColumn>
          }
        />
      </form>
    </IdentityChallengeQuestionWrapper>
  );
};

export default IdentityChallengeQuestion;
