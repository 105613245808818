import React from 'react';
import ReactDOM from 'react-dom';
import { Mixpanel } from 'analytics';

import App from './App';
import './styles.css';

import './ondeck-bootstrap/odbs.scss';
import { datadogRum } from '@datadog/browser-rum';
import './custom.css';

Mixpanel.init(window.env.REACT_APP_MIXPANEL_TOKEN);

if (window.env.REACT_APP_DATADOG_RUM_ENABLED) {
  datadogRum.init({
    applicationId: window.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
    clientToken: window.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'ondeck_merchant_ux',
    env: window.env.REACT_APP_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackSessionAcrossSubdomains: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      window.env.REACT_APP_BUTTERFLY_URL,
      window.env.REACT_APP_CHECKOUT_ORCHESTRATOR_URL,
      window.env.REACT_APP_OFFER_ORCHESTRATOR_URL,
      window.env.REACT_APP_SPICEROAD_URL
    ]
  });
  datadogRum.startSessionReplayRecording();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
