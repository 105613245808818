import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Tooltip,
  Link,
  Button,
  useErrorHandler,
  LoadingSpinner
} from '@ondeck/silkworm';
import { TooltipWrapper, Wrapper } from './CheckoutSuccess.style';
import { Container } from 'react-bootstrap';
import { Checkout } from 'states/checkoutState';
import Plaid from 'components/Plaid';
import { getDetailedOffer, getParties } from 'service';
import { useAuth } from '../../contexts/AuthContext';
import { isPlaidConnected } from 'pages/helpers/helpers';

interface CheckoutProps {
  checkoutData: Checkout;
}

const CheckoutSuccess = ({ checkoutData }: CheckoutProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { addCredentials, isAuthenticated } = useAuth();
  const { handleError } = useErrorHandler();
  const [productType, setProductType] = useState<string>('loc');
  const [showPlaid, setShowPlaid] = useState<boolean | null>(null);
  const onPlaidDismissed = () => {
    setShowPlaid(false);
  };

  const loadProductType = useCallback(async () => {
    try {
      const offerDataResponse = await getDetailedOffer(
        addCredentials,
        checkoutData.applicationUuid,
        checkoutData.offerUuid
      );
      setProductType(
        offerDataResponse.product === 'LineOfCredit' ? 'loc' : 'tl'
      );
    } catch (e) {
      handleError(e);
    }
  }, []);

  const loadShowPlaid = useCallback(async () => {
    try {
      const partiesData = await getParties(addCredentials);
      if (partiesData?.optimizely?.plaidAdoptionVariationKey === 'plaid_flow') {
        const plaidConnected = await isPlaidConnected(addCredentials);
        setShowPlaid(!plaidConnected);
      }
    } catch (e) {
      handleError(e);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        await loadProductType();
        await loadShowPlaid();
        setIsLoading(false);
      })();
    }
  }, [isAuthenticated, loadProductType, loadShowPlaid]);

  if (isLoading) {
    return (
      <LoadingSpinner>
        <FormattedMessage id="common.loading.message" />
      </LoadingSpinner>
    );
  }

  return showPlaid ? (
    <Plaid onPlaidDismissed={onPlaidDismissed} />
  ) : (
    <>
      <Wrapper data-testid="checkoutSuccess-loaded">
        <div>
          <h2>
            <FormattedMessage id="success.title" />
          </h2>
          <br />
          <FormattedMessage id={`success.${productType}.notice1`} />
          {productType === 'tl' && (
            <TooltipWrapper>
              <Tooltip>
                <FormattedMessage id="common.toolTip.sameDayFunding" />
              </Tooltip>
            </TooltipWrapper>
          )}
          <br />
          <br />
          <FormattedMessage
            id={`success.${productType}.notice2`}
            values={{
              b: string => (
                <span style={{ fontFamily: 'var(--fontSemiBold)' }}>
                  {string}
                </span>
              )
            }}
          />
        </div>
      </Wrapper>
      <Container className="justify-content-center d-flex">
        <Link href={window.env.REACT_APP_BAZAAR_URL} target="_self">
          <Button>
            <FormattedMessage id="common.button.returnHome" />
          </Button>
        </Link>
      </Container>
    </>
  );
};

export default CheckoutSuccess;
