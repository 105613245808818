import React from 'react';
import styled from 'styled-components';
import {
  Button,
  ConfirmInformation,
  SteppedProgressBar,
  defaultBreakpoints,
  FooterContainer,
  getMediaQuery
} from '@ondeck/silkworm';

export const mQMax = getMediaQuery(defaultBreakpoints, true);
export const mQ = getMediaQuery(defaultBreakpoints);

export const Margin = styled.div`
  margin: 8px;
`;

export const WrapperAgreements = styled.div`
  margin: 0 auto;
  max-width: 60rem;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #3c3c3c;
  ${mQMax.md`
    padding-left:15px;
    padding-right:15px;
  `}
  @media (min-width: ${defaultBreakpoints.md}) and (max-width: ${defaultBreakpoints.lg}) {
    padding-left: 2%;
    padding-right: 2%;
  }
`;

export const Header = styled.div`
  font-style: italic;
  font-size: 1rem;
  text-align: right !important;
  line-height: 1.375rem;
`;

export const Footer = styled.p`
  margin-top: 30px;
  margin-bottom: 1.875rem;
  font-size: 1rem;
`;

export const StyledSpan = styled.span`
  text-decoration: underline;
  font-size: 1rem;
  line-height: 1.375rem;
`;

export const StyledTitle = styled.h5`
  font-size: 1rem;
  font-family: GalanoGrotesque-Medium;
  line-height: 1.875rem !important;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
  ${mQ.lg`
    font-size: 1.25rem;
    margin-top: 1.875rem;
  `}
`;

export const StyledParagraph = styled.p`
  margin-top: 0px;
  margin-bottom: 1.875rem;
`;

export const StyledHr = styled.hr`
  border-top: 1px solid #cfd3d4;
  height: 0;
  border-bottom: none;
  padding: 0;
  margin-bottom: 10px;
`;

export const VerifyIdentityButton = styled(Button)`
  margin-top: -30px !important;
`;

export const IdentityContainer = styled.div`
  display: flex;
  flex-direction: column;
  left: 8%;
  margin-top: -30px;
  max-width: 430px;
  position: relative;

  @media (max-width: 500px) {
    left: 28%;
  }
`;

export const RequiredFirstDrawContainer = styled.div`
  margin: 0 auto;
  padding: 10px 0;
  max-width: 477px;
  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

export const FooterColumn = styled(FooterContainer)`
  align-items: center;
  flex-direction: column;
  row-gap: 12px;
`;
