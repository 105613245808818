import React, { useEffect } from 'react';
import { useUserData } from '../../contexts/UserContext';
import { useAuth } from '../../contexts/AuthContext';

declare global {
  interface Window {
    FS: any;
    env: any;
  }
}

window.FS = window.FS || {};

interface FSIdentify {
  displayName?: string;
  email?: string;
}

interface FSProps {
  userVars?: FSIdentify;
}

const FullStory = ({ userVars = {} }: FSProps) => {
  const { user } = useAuth();
  const { userName, userId } = useUserData();

  userVars = {
    displayName: userName,
    email: user?.email,
    ...userVars
  };
  useEffect(() => {
    if (userId && userName && window.FS) {
      window.FS.identify(userId, userVars);
    }
  }, [userId, userName, userVars]);

  return user ? <span data-testid="fs-identify"></span> : null;
};
export default FullStory;
