import React, { useEffect, useState } from 'react';
import { MoneyFormat, DecimalFormat } from '../TextFormat';
import { codToCents, isStateRegSpecific } from 'pages/helpers/helpers';
import ToolTip from '../common/ToolTip';
import { Card, Stack, Container } from 'react-bootstrap';
import { Offer } from '../../states/offerState';
import { Checkout } from 'states/checkoutState';
import { Application } from '../../states/applicationState';
import { FormattedMessage, useIntl } from 'react-intl';

interface LocReviewProps {
  offerData: Offer;
  application: Application;
  checkoutData: Checkout;
}

const LocReview = ({
  offerData,
  application,
  checkoutData
}: LocReviewProps) => {
  const intl = useIntl();

  const stateRegSpecificText = isStateRegSpecific(application)
    ? '.stateRegSpecific'
    : '';

  return (
    <Container data-testid="LocOffer" className="text-center container">
      <h1
        className="mt-4 fw-semibold text-secondary ms-1"
        style={{ fontSize: '38px', marginBottom: '24px' }}
      >
        <FormattedMessage id="offer.reviewWithRequiredFirstDraw.title" />
      </h1>
      <Card className="pb-2 mh-100 customCard shadow">
        <Stack className="my-4">
          <DecimalFormat
            prefix="$"
            value={offerData?.lineAmount}
            className="display-3 mb-0 fw-600"
          />
          <FormattedMessage id="offer.review.approvedCreditLimit" />
        </Stack>
        <Card
          className="p-4 mx-3 mx-md-4 mt-2 mb-3 my-overflow-auto"
          style={{ backgroundColor: '#f3f6fa' }}
        >
          <Stack gap={4} className="fs-4 flex-md-row text-start lh-1">
            <Stack gap={3}>
              <div className="fs-3 fw-bold">Line of Credit Details</div>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.locApprovedCreditLimit" />
                <DecimalFormat
                  prefix="$"
                  value={offerData?.lineAmount}
                  className="ms-auto"
                />
              </Stack>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.term" />
                <ToolTip
                  text={
                    <FormattedMessage
                      id="offer.review.termToolTipText"
                      values={{
                        termInMonths: offerData?.term
                      }}
                    />
                  }
                />
                <span className="ms-auto">{offerData?.term} Months</span>
              </Stack>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.cod" />
                <ToolTip
                  text={
                    <FormattedMessage
                      id="offer.review.codToolTipText"
                      values={{ Break: <p className="mb-0">&nbsp;</p> }}
                    />
                  }
                />
                <MoneyFormat
                  value={codToCents(offerData?.trueCentsOnDollar)}
                  format="cents"
                  className="ms-auto"
                  oneDecimalFormat={true}
                />
              </Stack>
              <Stack direction="horizontal">
                <FormattedMessage
                  id={`offer.review.apr${stateRegSpecificText}`}
                />
                <DecimalFormat
                  className="ms-auto"
                  value={offerData?.trueAPR}
                  suffix="%"
                />
              </Stack>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.rid" />
                <DecimalFormat
                  prefix="$"
                  value={offerData?.requiredInitialDraw}
                  className="ms-auto"
                />
              </Stack>

              <hr className="d-block border-line border-dark my-0" />
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.monthlyFee" />
                <DecimalFormat
                  prefix="$"
                  value={offerData?.monthlyFee}
                  className="ms-auto"
                />
              </Stack>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.originationFee" />
                <DecimalFormat
                  prefix="$"
                  value={offerData?.loanOriginationFee}
                  className="ms-auto"
                />
              </Stack>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.returnedPaymentFee" />
                <DecimalFormat
                  prefix="$"
                  value={offerData?.returnedPaymentFee}
                  className="ms-auto"
                />
              </Stack>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.lateFee" />
                <DecimalFormat
                  prefix="$"
                  value={offerData?.lateFee}
                  className="ms-auto"
                />
              </Stack>
            </Stack>
            <div className="d-none d-md-block vr border border-line" />
            <hr className="d-block d-md-none border-line border-dark my-0" />
            <Stack gap={3}>
              <div className="fs-3 fw-bold">Selected Draw Details</div>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.selectedDrawAmount" />
                <DecimalFormat
                  prefix="$"
                  value={checkoutData?.selectedDrawAmount}
                  className="ms-auto"
                />
              </Stack>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.numberOfPayments" />
                <div className="ms-auto">{offerData?.numberOfPayments}</div>
              </Stack>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.estimatedPaymentAmount" />
                <DecimalFormat
                  prefix="$"
                  value={checkoutData?.estimatedPaymentAmount}
                  className="ms-auto"
                />
              </Stack>
              <Stack direction="horizontal">
                <FormattedMessage id="offer.review.paymentFrequency" />
                <div className="ms-auto">
                  {offerData?.paymentFrequency.toUpperCase() === 'WEEKLY'
                    ? 'Weekly'
                    : 'Monthly'}
                </div>
              </Stack>
            </Stack>
          </Stack>
          <Stack className="mt-4 fs-4 text-start"></Stack>
        </Card>
      </Card>
    </Container>
  );
};

export default LocReview;
