import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useErrorHandler, LoadingSpinner } from '@ondeck/silkworm';
import { Checkout } from 'states/checkoutState';
import Plaid from 'components/Plaid';
import { postNextState } from 'service';
import { useAuth } from '../../contexts/AuthContext';
import { Application } from 'states/applicationState';
import {
  showPlaidPreAgreementFlow,
  trackOptimizelyEvent
} from 'pages/helpers/helpers';

interface PlaidStepProps {
  checkoutData: Checkout;
  onCheckoutDataChange(checkoutData: object): void;
  application: Application;
}

const PlaidStep = ({
  checkoutData,
  onCheckoutDataChange,
  application
}: PlaidStepProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { addCredentials, isAuthenticated } = useAuth();
  const { handleError } = useErrorHandler();
  const [skipBtn, showSkipBtn] = useState<boolean>(false);

  const onPlaidDismissed = async () => {
    setIsLoading(true);
    const checkoutDataRes = await postNextState(
      addCredentials,
      checkoutData.checkoutUuid,
      checkoutData.offerUuid,
      checkoutData.uxComponentRef
    );
    onCheckoutDataChange(checkoutDataRes);
    setIsLoading(false);
  };

  const onPlaidConnect = async () => {
    await trackOptimizelyEvent(addCredentials, 'connect_plaid');
  };

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const data = await showPlaidPreAgreementFlow(
            addCredentials,
            checkoutData?.routingNumber,
            application
          );
          if (data?.showPlaid) {
            data?.skip_plaid_enabled && showSkipBtn(true);
            setIsLoading(false);
          } else {
            await onPlaidDismissed();
          }
        } catch (e) {
          handleError(e);
        }
      })();
    }
  }, [isAuthenticated, skipBtn]);

  if (isLoading) {
    return (
      <LoadingSpinner>
        <FormattedMessage id="common.loading.message" />
      </LoadingSpinner>
    );
  }

  return (
    <Plaid
      onPlaidDismissed={onPlaidDismissed}
      onPlaidConnect={onPlaidConnect}
      skip_plaid_btn={skipBtn}
    />
  );
};

export default PlaidStep;
