import React, { useEffect, useLayoutEffect } from 'react';
import { useTracking } from 'react-tracking';
import { Button, GenericMessage, Link, PhoneLink } from '@ondeck/silkworm';
import { useIntl, FormattedMessage } from 'react-intl';
import Page404 from 'assets/page404.svg';

interface NotFoundPageProps {
  setHideSignOut: Function;
}

const NotFoundPage = ({ setHideSignOut }: NotFoundPageProps) => {
  const intl = useIntl();
  const { trackEvent } = useTracking();

  const eventName = 'Not Found Page';
  useEffect(() => {
    trackEvent({ event: `${eventName} Loaded` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    setHideSignOut(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-5" aria-label="GenericMessage:Wrapper">
      <GenericMessage
        image={Page404}
        title={intl.formatMessage({ id: 'notFoundPage.title' })}
      >
        <p className="mt-3">
          <FormattedMessage
            id="notFoundPage.content"
            values={{
              phoneLink: (
                <PhoneLink
                  phoneNumber={intl.formatMessage({
                    id: 'common.contactNumber'
                  })}
                />
              )
            }}
          />
        </p>
        <Link href={window.env.REACT_APP_BAZAAR_URL} target="_self">
          <Button>
            <FormattedMessage id="common.button.returnHome" />
          </Button>
        </Link>
      </GenericMessage>
    </div>
  );
};

export default NotFoundPage;
