import styled from 'styled-components';
import { ConfirmationCard } from '@ondeck/silkworm';
import { mQ } from 'pages/styles/common';

export const Subtitle = styled.div`
  font-size: 17px;
  margin: 0 20px;
  ${mQ.md`
    margin: 0 80px;
  `}
`;

export const TooltipWrapper = styled.span`
  padding-left: 5px;
`;

export const Heading = styled.div`
  text-align: left;
  h3 {
    font-family: var(--fontBold);
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
`;

export const StyledConfirmationCard = styled(ConfirmationCard)`
  & .ConfirmationCardstyle__CardImageContainer-dmfUkt {
    display: none;
  }
  padding-bottom: 50px;
  padding-top: 50px;
  div:first-child {
    position: relative;
    top: 0;
    padding: 0 12px;
    ${mQ.md`
      padding: 0 84px;
    `}
  }
  form {
    padding: 0 12px;
    ${mQ.md`
      padding: 0;
    `}
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    ${mQ.md`
      padding: 0 80px;
    `}
  }

  li {
    padding-bottom: 20px;
    padding-left: 30px;
    position: relative;
  }
`;

export const StyledAnchor = styled.a`
  cursor: pointer;
  font-family: var(--fontSemiBold);
  font-size: var(--fontSizesSubtext);
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: var(--fontColorsActiveLink);
  }
  &:visited {
    color: var(--fontColorsVisitedLink);
  }
`;

export const InnerWrapper = styled.div`
  margin-top: -50px;
`;
