import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Stack, Card, Alert } from 'react-bootstrap';
import checkDesktopSVG from './check-image-desktop.svg';
import checkMobileSVG from './check-image-mobile.svg';
import { validateAccountNumber, validateRouting } from 'service/service';
import { BackArrow } from '@ondeck/silkworm';
import { useAuth } from '../../contexts/AuthContext';

interface BankDataProps {
  onBack: Function;
  type: 'LOC' | 'TL';
  applicationId: string;
  onSubmit: (v) => void;
}

interface BankInfoProps {
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

interface ErrorsProps {
  routingNumber: string | boolean | undefined;
  accountNumber: string | boolean | undefined;
  accountConfirm: string | boolean | undefined;
}

const errorsInit = {
  routingNumber: undefined,
  accountNumber: undefined,
  accountConfirm: undefined
};

const BankData = ({
  onBack = () => {},
  type = 'LOC',
  applicationId,
  onSubmit
}: BankDataProps) => {
  const intl = useIntl();
  const [bankData, setBankData] = useState<BankInfoProps>();
  const [errors, setErrors] = useState<ErrorsProps>(errorsInit);
  const [alert, setAlert] = useState<string>();
  const [disabled, setDisabled] = useState(true);
  const { addCredentials } = useAuth();

  const setError = (obj: object) =>
    Object.entries(obj).map(([k, v]) => setErrors(e => ({ ...e, [k]: v })));
  const hasErrors = () => Object.entries(errors).some(([k, v]) => v !== false);
  const hasError = error => error === true || typeof error === 'string';

  useEffect(() => {
    setDisabled(hasErrors());
  }, [errors]);

  const handleRoutingValidation = value =>
    !/^\d{9}$/.test(value)
      ? setError({
          routingNumber: intl.formatMessage({ id: 'bankData.invalid.routing' })
        })
      : validateRouting(addCredentials, value)
          .then(data =>
            Object.keys(data).length
              ? (setBankData(data), setError({ routingNumber: false }))
              : (setAlert(
                  intl.formatMessage({ id: 'bankData.alert.somethingWrong' })
                ),
                setError({
                  routingNumber: intl.formatMessage({
                    id: 'bankData.invalid.routing'
                  })
                }))
          )
          .catch(
            () => (
              setAlert(
                intl.formatMessage({ id: 'bankData.alert.somethingWrong' })
              ),
              setError({ routingNumber: true })
            )
          );

  const handleAccountValidation = accountNumber => {
    return validateAccountNumber(addCredentials, {
      accountNumber,
      applicationId
    })
      .then(data => {
        if (data.isValid) {
          setError({ accountNumber: false });
          return true;
        } else {
          setAlert(intl.formatMessage({ id: 'bankData.alert.mismatch' }));
          setError({ accountNumber: true });
          return false;
        }
      })
      .catch(() => {
        setAlert(intl.formatMessage({ id: 'bankData.alert.somethingWrong' }));
        setError({ accountNumber: true });
        return false;
      });
  };

  const handleAccountBlur = (key, value) =>
    !/^\d{6,17}$/.test(value)
      ? setError({ [key]: intl.formatMessage({ id: 'bankData.invalid.bank' }) })
      : setError({ [key]: false });

  const handleConfirmBlur = (key, value) =>
    !/^\d{6,17}$/.test(value) &&
    setError({ [key]: intl.formatMessage({ id: 'bankData.invalid.bank' }) });

  const confirmAccount = (accountNumber, accountConfirm) =>
    !/^\d{6,17}$/.test(accountConfirm)
      ? setError({
          accountConfirm: intl.formatMessage({ id: 'bankData.invalid.bank' })
        })
      : accountNumber === accountConfirm
      ? setError({ accountConfirm: false, accountNumber: false })
      : setError({
          accountConfirm: intl.formatMessage({
            id: 'bankData.invalid.inputMatch'
          })
        });

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    setAlert(undefined);
    const accountConfirm = e.currentTarget.accountConfirm.value;
    const accountNumber = e.currentTarget.accountNumber.value;
    if (
      accountConfirm.length > 5 &&
      accountConfirm.length >= accountNumber.length
    )
      confirmAccount(accountNumber, accountConfirm);
  };

  const handleFormBlur = (e: React.FormEvent<HTMLFormElement>) => {
    const accountConfirm = e.currentTarget.accountConfirm.value;
    const accountNumber = e.currentTarget.accountNumber.value;
    if (accountConfirm.length > 5)
      confirmAccount(accountNumber, accountConfirm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const accountNumber = e.currentTarget.accountNumber.value;
    const routingNumber = e.currentTarget.routingNumber.value;
    e.preventDefault();
    const accountValid = await handleAccountValidation(accountNumber);
    accountValid &&
      onSubmit({ routingNumber, accountNumber, bankName: bankData?.name });
  };

  return (
    <>
      <BackArrow onClick={onBack} absolutePositioned={true} className="mt-4" />
      <Form
        noValidate
        onSubmit={e => handleSubmit(e)}
        onChange={e => handleFormChange(e)}
        onBlur={e => handleFormBlur(e)}
      >
        <Stack className="text-center container pt-3" gap={4}>
          <h1
            className={`fw-semibold text-secondary ms-1`}
            style={{ fontSize: '38px' }}
          >
            <FormattedMessage id="bankData.header" />
          </h1>
          <Card className="p-4 pb-5">
            {alert && (
              <Alert
                variant="danger"
                className="p-3 text-start"
                style={{ background: '#fdf3f4', color: '#c91528' }}
              >
                {alert}
              </Alert>
            )}
            <Stack gap={4}>
              <div className="h1 m-0 p-0">
                <FormattedMessage id="bankData.title" />
              </div>
              <span className="text-start fs-4">
                <FormattedMessage
                  id="bankData.subtitle"
                  values={{
                    b: c => <span className="fw-bold">{c}</span>
                  }}
                />
              </span>
              <Stack gap={4} className="fs-4 flex-md-row text-start pt-2">
                <Stack className="col" gap={4}>
                  <Form.Floating>
                    <Form.Control
                      className="fs-3"
                      data-testid="routing-input"
                      name="routingNumber"
                      onChange={() => setError({ routingNumber: undefined })}
                      onBlur={async e =>
                        handleRoutingValidation(e.currentTarget.value)
                      }
                      isValid={errors.routingNumber === false}
                      isInvalid={hasError(errors.routingNumber)}
                      type="text"
                    />
                    <label className="text-text">
                      <FormattedMessage id="bankData.routing" />
                    </label>
                    <Form.Control.Feedback
                      type="valid"
                      className="text-text text-end lh-sm"
                    >
                      {bankData?.name && (
                        <>
                          {bankData?.name}
                          <br />
                          {`${bankData?.address} ${bankData?.city}, ${bankData?.state} ${bankData?.zipCode}`}
                        </>
                      )}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.routingNumber}
                    </Form.Control.Feedback>
                  </Form.Floating>

                  <Form.Floating>
                    <Form.Control
                      className="fs-3"
                      data-testid="account-input-1"
                      name="accountNumber"
                      isValid={errors.accountNumber === false}
                      isInvalid={hasError(errors.accountNumber)}
                      onBlur={e =>
                        handleAccountBlur(
                          'accountNumber',
                          e.currentTarget.value
                        )
                      }
                      onChange={() => setError({ accountNumber: undefined })}
                    />
                    <label className="text-text">
                      <FormattedMessage id="bankData.account" />
                    </label>
                    <Form.Control.Feedback type="invalid">
                      {errors.accountNumber}
                    </Form.Control.Feedback>
                  </Form.Floating>

                  <Form.Floating>
                    <Form.Control
                      className="fs-3"
                      data-testid="account-input-2"
                      name="accountConfirm"
                      isValid={errors.accountConfirm === false}
                      isInvalid={hasError(errors.accountConfirm)}
                      onBlur={e =>
                        handleConfirmBlur(
                          'accountConfirm',
                          e.currentTarget.value
                        )
                      }
                      onChange={() => setError({ accountConfirm: undefined })}
                    />
                    <label className="text-text">
                      <FormattedMessage id="bankData.confirm" />
                    </label>
                    <Form.Control.Feedback type="invalid">
                      {errors.accountConfirm}
                    </Form.Control.Feedback>
                  </Form.Floating>
                </Stack>
                <img
                  src={checkDesktopSVG}
                  width="50%"
                  className="mb-auto d-none d-md-block"
                  alt="Check example"
                />
                <img
                  src={checkMobileSVG}
                  width="100%"
                  className="mb-auto d-block d-md-none"
                  alt="Check example"
                />
              </Stack>
            </Stack>
          </Card>
          <div className="fs-5 lh-sm text-start">
            <FormattedMessage id={`bankData.${type}.disclaimer`} />
          </div>
          <div>
            <Button
              className="rounded-pill px-4"
              type="submit"
              data-testid="submit"
              disabled={disabled}
            >
              <FormattedMessage id="bankData.continue" />
            </Button>
          </div>
        </Stack>
      </Form>
    </>
  );
};

export default BankData;
