import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useErrorHandler, LoadingSpinner } from '@ondeck/silkworm';
import ConfirmBank from 'components/InformationConfirmation/sections/ConfirmBank';
import {
  getBankInfo,
  getDetailedOffer,
  postNextState,
  postPreviousState
} from 'service';
import { Checkout } from 'states/checkoutState';
import { Application } from 'states/applicationState';
import BankData from 'components/BankData/BankData';
import { Offer, offerInitialState } from 'states/offerState';
import { BankAccount, bankInfoInitialState } from 'states/bankInfoState';
import { useAuth } from '../../contexts/AuthContext';
import { trackOptimizelyEvent } from 'pages/helpers/helpers';

interface InfoConfProps {
  checkoutData: Checkout;
  onCheckoutDataChange(checkoutData: object): void;
  application: Application;
}

const InformationConfirmation = ({
  checkoutData,
  onCheckoutDataChange,
  application
}: InfoConfProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [offerData, setOfferData] = useState<Offer>(offerInitialState);
  const [bankInfo, setBankInfo] = useState<BankAccount>(bankInfoInitialState);

  const { isAuthenticated, addCredentials } = useAuth();
  const { handleError } = useErrorHandler();

  useEffect(() => {
    isAuthenticated &&
      (async () => {
        try {
          if (application.type === 'RENEWAL') {
            const bankInfo = await getBankInfo(
              addCredentials,
              checkoutData.checkoutUuid
            );
            setBankInfo(bankInfo);
          }

          const offerDataResponse = await getDetailedOffer(
            addCredentials,
            checkoutData.applicationUuid,
            checkoutData.offerUuid
          );
          setOfferData(offerDataResponse);
        } catch (e) {
          handleError(e);
        }
        window.scrollTo(0, 0);
        setIsLoading(false);
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    window.onpopstate = () => {
      window.location.replace(window.env.REACT_APP_BAZAAR_URL);
    };
  });

  const handleBack = async () => {
    setIsLoading(true);
    try {
      const checkoutDataRes = await postPreviousState(
        addCredentials,
        checkoutData.checkoutUuid,
        checkoutData.uxComponentRef
      );
      onCheckoutDataChange(checkoutDataRes);
    } catch (e) {
      handleError(e);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (value?: {
    routingNumber: string;
    accountNumber: string;
    bankName: string;
  }) => {
    setIsLoading(true);
    try {
      trackOptimizelyEvent(addCredentials, 'submit_bank_info');
      const checkoutDataRes = await postNextState(
        addCredentials,
        checkoutData.checkoutUuid,
        checkoutData.offerUuid,
        checkoutData.uxComponentRef,
        value?.routingNumber,
        value?.accountNumber,
        value?.bankName
      );
      onCheckoutDataChange(checkoutDataRes);
    } catch (e) {
      handleError(e);
    }
    setIsLoading(false);
  };

  return isLoading || !isAuthenticated ? (
    <LoadingSpinner>
      <FormattedMessage id="common.loading.message" />
    </LoadingSpinner>
  ) : application.type === 'NEW' ? (
    <BankData
      type={offerData.product === 'LineOfCredit' ? 'LOC' : 'TL'}
      applicationId={checkoutData.applicationUuid}
      onSubmit={value => handleSubmit(value)}
      onBack={handleBack}
    />
  ) : (
    <ConfirmBank
      onBack={handleBack}
      onNext={handleSubmit}
      bankAccount={bankInfo}
    />
  );
};

export default InformationConfirmation;
