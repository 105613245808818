import React from 'react';
import { FormattedDate } from 'react-intl';
import {
  Margin,
  WrapperAgreements,
  Header,
  Footer,
  StyledSpan,
  StyledTitle,
  StyledParagraph,
  StyledHr
} from 'pages/styles/common';
// generated from /frontend/scripts
import lastModifiedDate from 'pages/generated-dates/terms-date';

const TermsPage = () => (
  <Margin>
    <WrapperAgreements data-testid="Wrapper:Agreements">
      <Header data-testid="DateHeader:StyledDiv">
        Updated and effective{' '}
        <FormattedDate
          value={lastModifiedDate}
          year="numeric"
          month="long"
          day="2-digit"
        />
      </Header>
      <StyledTitle>1. OnDeck Terms and Conditions</StyledTitle>
      <StyledParagraph>
        The OnDeck service and website (each as defined below) are operated by
        On Deck Capital, Inc., ODK Capital, LLC, or a subsidiary of affiliate
        thereof (collectively, “OnDeck”, “we”, or “us”). OnDeck is in the
        business of providing customers with small business loans, lines of
        credit and related services (“Service”). OnDeck is not a broker,
        investment advisor or financial planner, and we do not provide
        financial, securities, legal or tax advice. Before making any decision
        or implementing any strategy you should consider obtaining additional
        information and advice from your accountant, attorney, and/or other
        advisors. “You” is defined as the owner of the company completing the
        application on behalf of the company seeking credit. Unless otherwise
        noted, any agreements, authorizations or consents made by you or
        permissions granted to you hereunder also apply and are binding on your
        company seeking credit directly from OnDeck, or from a bank that
        originates credit through the OnDeck platform (“OnDeck Banking
        Partner”).
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>2. Accepting the Terms</StyledTitle>
      <StyledParagraph>
        By using the information, tools, features and functionality located at
        ondeck.com or subdomains (the “Website”) or by submitting an application
        for credit to OnDeck, you signify that you have read, understand and
        agree to be bound by these terms and conditions (the “Agreement”) for
        any and all applications for credit to OnDeck or an OnDeck Banking
        Partner. You further agree all information being provided by your
        company as the borrower and/or by you (either as a guarantor or as an
        authorized representative of your company) as part of the application
        process for the Loan is true and complete.
      </StyledParagraph>
      <StyledParagraph>
        You may not use the Service and you may not accept this Agreement if you
        are under 18 years of age. You represent that you have the capacity to
        bind the company or entity applying for credit, on behalf of such
        company or entity. You understand that, other than with regard to loans
        under the SBA Paycheck Protection Program (“PPP loans”), (a) we will
        require you to sign a personal guarantee for the credit obtained by your
        company from OnDeck, and (b) for term loans, we will take a general lien
        on your business’s assets. Before you continue, you should print or save
        a copy of this Agreement for your records.
      </StyledParagraph>
      <StyledParagraph>
        In addition to this Agreement you and your company may enter into other
        agreements, including a loan agreement or promissory note that will
        govern the terms of your company’s loan or line of credit and use of the
        Service. If there is a conflict between this Agreement and such other
        agreements that is applicable to specific aspects of the Service, the
        other agreement shall govern with respect to those specific aspects.
      </StyledParagraph>
      <StyledParagraph>
        OnDeck may make changes to this Agreement from time to time. If we do
        this, the date at the top of this page indicates when the latest
        revisions were made. Your continued use of the Service or the Website
        will constitute acceptance of such revisions.
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>3. Credit and Background Check Authorization</StyledTitle>
      <StyledParagraph>
        You understand and agree that OnDeck and our agents and assignees are
        authorized to contact third parties to conduct background checks and
        other investigative reports, including but not limited to your business
        credit card sales data as described below, and make credit report
        inquiries (including requesting personal credit bureau reports about you
        and any other owner(s) of the business who will be providing a personal
        guarantee, and business credit bureaus about your company, in either
        case, from credit reporting agencies and other sources) or for any other
        lawful purpose. OnDeck has such authority for the foregoing in
        connection with any extension of credit to the company on whose behalf
        you are applying, conducting loan file updates, ongoing loan reviews,
        renewal of financing, or referral of your business to third party
        lenders. Upon your written request, we will advise you if we obtained a
        credit report. You understand and agree that OnDeck is making no
        commitment or guarantee that any product, evaluation, or guidance
        provided by OnDeck will result in an approval of credit from OnDeck or
        any OnDeck Banking Partner.
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>4. Privacy, Personal and Company Information</StyledTitle>
      <StyledParagraph>
        For information about OnDeck’s data protection practices, please read
        our Privacy Policy, which can be accessed on the Website, as modified
        from time to time, which is hereby incorporated into this Agreement. The
        Privacy Policy explains how OnDeck collects and treats your personal and
        company information, and protects your privacy, when you access OnDeck
        and use the Service. The policy may be updated from time to time at our
        discretion. Changes will be effective upon posting to the Website.
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>5. Identity Verification</StyledTitle>
      <StyledParagraph>
        To help the government fight the funding of terrorism and money
        laundering activities, federal law requires financial institutions to
        obtain, verify, and record identifying information that identifies each
        person and entity applying for credit. Therefore, when your company
        applies for credit, we ask for your name, address, and other information
        that allows us to identify you and your company. We may also ask for a
        driver’s license or other identifying documents. You allow us to obtain
        such information and share the information with third parties to help us
        verify you and your company’s identity.
      </StyledParagraph>
      <StyledParagraph>
        In connection with the provision of the Service, users may submit
        financial information from their business banking account(s) to OnDeck.
        OnDeck and OnDeck Banking Partners use this information for lending
        decisions and may require updates to monitor the performance of
        customers on an ongoing basis. With the Service, users may direct OnDeck
        to retrieve your company information maintained online or otherwise by
        third-party financial institutions or organizations authorized to house
        such information.
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>6. Proprietary Rights</StyledTitle>
      <StyledParagraph>
        All content on the Website or used by the Service, including but not
        limited to text, names, designs, pictures, information and software are
        the proprietary property of OnDeck with all rights reserved.
      </StyledParagraph>
      <StyledParagraph>
        You are permitted to use content delivered to you through the Service or
        Website only on the Service. You may not copy, reproduce, modify,
        distribute, or create derivative works from this content. Further, you
        agree not to reverse engineer or reverse compile any of the Service
        technology.
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>7. Electronic Communications</StyledTitle>
      <StyledParagraph>
        You hereby consent to receive by electronic means, (or in writing
        through postal mail or email), this Agreement, communications,
        disclosures and notices, including and any and all disclosures and/or
        notices required to be given by applicable law or regulation. You also
        consent to allow OnDeck, or an OnDeck Banking Partner, to respond to any
        inquiries by e-mail, at the email address you have provided, regardless
        of the format of the original inquiry. You must also agree to our ESign
        consent during the online application process.
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>8. Telephone Communications</StyledTitle>
      <StyledParagraph>
        You authorize OnDeck and those acting on its behalf to deliver or cause
        to be delivered advertisements or telemarketing messages, including
        calls, text messages, and SMS and MMS messages, using an automatic
        telephone dialing system (often referred to as an autodialer) or an
        artificial or prerecorded voice, to the telephone numbers you have
        provided in your application for credit to OnDeck (including your
        cellular phone numbers). You agree that this consent applies even if the
        numbers you have provided are currently on any state, federal, or
        corporate Do-Not-Call registry. You understand that you are not required
        to provide this consent as a condition of receiving any credit or
        services from OnDeck, and that you may apply for business credit by
        contacting us directly. You may opt out of receiving calls and marketing
        from OnDeck and its affiliates, marketing partners, agents and others as
        provided in the Privacy Policy or by contacting us directly.
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>9. Loan Return Policy</StyledTitle>
      <StyledParagraph>
        You agree that you may not return any loan or other financial product
        that you take from OnDeck, or an OnDeck Banking Partner, except at
        OnDeck’s or the OnDeck Banking Partner’s sole discretion, it being
        understood that in no event will you be able to return any such product
        after 5:00 p.m. EST on the second business day following the initiation
        of disbursement of funds by OnDeck, and no returns of renewal loans or
        lines of credit will be permitted.
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>10. Third Party Sites or Content</StyledTitle>
      <StyledParagraph>
        The Website or other materials sent to you under the Service may contain
        links to other Websites (“Third Party Sites”), as well as content from
        third parties. OnDeck is not responsible for such Third Party Sites or
        content from third parties, including without limitation accuracy,
        reliability, offensiveness, or appropriateness. If you decide to leave
        the Website and access Third Party Sites or third party content, this
        Agreement and OnDeck policies no longer apply.
      </StyledParagraph>
      <StyledParagraph>
        In addition, if any such link is to another capital provider or lender,
        OnDeck acts solely as a referrer and does not guarantee or underwrite
        the products or services, including credit products, provided by such
        capital provider or lender. You will be deemed a customer of that
        capital provider or lender with respect to any products or services
        provided by such capital provider or lender. OnDeck has no role in, nor
        any liability for any decision made or product or service provided by
        such other capital provider or lender.
      </StyledParagraph>
      <StyledHr />
      <StyledTitle>11. Miscellaneous</StyledTitle>
      <StyledTitle>Limitation on Liability</StyledTitle>
      <StyledParagraph>
        TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE OR OUR
        DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR
        ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
        PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA, ARISING
        FROM YOUR USE OF THE WEBSITE OR THE SERVICES, INCLUDING THE
        UNAVAILABILITY OF THE SERVICES FOR ANY REASON, OR ANY SYSTEM FAILURE OR
        MALFUNCTION ASSOCIATED WITH THE SERVICES, AND INCLUDING ANY THIRD PARTY
        CLAIMS, WHETHER BASED ON THEORIES OF BREACH OF CONTRACT, TORT, PRODUCT
        LIABILITY, OR BREACH OF WARRANTY. THESE LIMITATIONS OF LIABILITY WILL
        APPLY EVEN IF WE ARE ADVISED ON THE POSSIBILITY OF SUCH DAMAGES OR
        CLAIMS. THE PARTIES UNDERSTAND THAT THE SERVICES BY US WOULD NOT BE
        PROVIDED WITHOUT SUCH LIMITATIONS.
      </StyledParagraph>
      <StyledTitle>Warranty</StyledTitle>
      <StyledParagraph>
        WE DISCLAIM ANY WARRANTY OF MERCHANT LIABILITY OR FITNESS FOR A
        PARTICULAR PURPOSE, ANY LIABILITY FOR ERRORS OR OMISSIONS IN ANY
        INFORMATION PROVIDED AS PART OF THE SERVICES OR ON THE WEBSITE AND ANY
        WARRANTIES REGARDING THE OPERABILITY OF THE SERVICES OR LIABILITY FOR
        ANY SERVICE INTERRUPTIONS OR SYSTEM FAILURES THAT MAY AFFECT THE
        SERVICES AT ANY TIME.
      </StyledParagraph>
      <StyledTitle>Governing Law; Venue and Jurisdiction</StyledTitle>
      <StyledParagraph>
        By accessing the Website or using Services, you agree that the laws of
        the state of Virginia (or the home state of the OnDeck Banking Partner),
        without regard to any principles of conflict of laws that would require
        or permit the application of laws of any other jurisdiction, will govern
        this Agreement or your use of the Services.
      </StyledParagraph>
      <StyledHr />
      <Footer>
        <StyledSpan>For Ohio applicants</StyledSpan>: You understand that the
        Ohio laws against discrimination require that all creditors make credit
        equally available to all credit worthy customers, and that credit
        reporting agencies maintain separate credit histories on each individual
        upon request. The Ohio Civil Rights Commission administers compliance
        with this law.
      </Footer>
    </WrapperAgreements>
  </Margin>
);

export default TermsPage;
